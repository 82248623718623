import React from 'react';
import { FaStar, FaQuoteLeft } from "react-icons/fa";

export default function Testimonial() {
  const testimonials = [
    {
      text: "Tonle Talk បានផ្តល់កន្លែងខ្ញុំអានដែលសប្បាយ ហើយបានធ្វើឲការរៀនអានកាន់តែងាយ!",
      name: "Kimlang -C",
      rating: 4,
      image: "https://i.pravatar.cc/150?img=1"
    },
    {
      text: "អរគុណដល់ Tonle Talk ដែលបានផ្តល់កន្លែងរៀនអង់គ្លេសដែលធូថ្លៃដែលខ្ញុំអាចរៀនបានគ្រប់កន្លែង",
      name: "Sophal",
      rating: 5,
      image: "https://i.pravatar.cc/150?img=2"
    },
    {
      text: "ការរៀនបូកមានហ្វឹកហាត់ក្នុងកន្លែងរៀនពីកំរិត 0 គឺល្អ",
      name: "Kanha",
      rating: 5,
      image: "https://i.pravatar.cc/150?img=3"
    }
  ];

  const generateStars = (rating) => {
    return Array.from({ length: 5 }, (_, i) => (
      <FaStar key={i} className={`w-4 h-4 ${i < rating ? 'text-yellow-400' : 'text-gray-300'}`} />
    ));
  };

  return (
    <div className="py-2">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-500 hover:scale-105">
              <div className="p-8">
                <div className="flex items-center mb-4">
                  <div>
                    <h3 className="font-semibold text-lg text-gray-800">{testimonial.name}</h3>
                    <div className="flex mt-1">
                      {generateStars(testimonial.rating)}
                    </div>
                  </div>
                </div>
                <div className="relative">
                  <FaQuoteLeft className="absolute top-0 left-0 text-blue-200 opacity-50 transform -translate-x-2 -translate-y-2" size={24} />
                  <p className="text-gray-600 khmer relative z-10 pl-6 pt-2">"{testimonial.text}"</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}