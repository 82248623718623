import React from 'react';
import { FaChartBar, FaClipboardList, FaLock, FaStar } from 'react-icons/fa';

const PremiumParts = ({ onLearningProgressClick, onSubscriptionClick, accessStatus }) => {
  return (
    <div className="bg-white rounded-lg p-4 mt-6 border border-gray-200">
      {/* Learning Progress Button */}
      <button
        onClick={onLearningProgressClick}
        className="flex items-center justify-start w-full hover:bg-green-200 pb-2 border-b transition-colors"
      >
        <FaChartBar className="text-green-500 text-xl" />
        <span className="ml-4 text-lg md:text-xl font-semibold text-gray-800 khmer">ការរៀនខ្ញុំ</span>
      </button>

      {/* Subscriptions Button */}
      <button
        onClick={onSubscriptionClick}
        className="flex items-center justify-start w-full py-2 border-b hover:bg-blue-200 transition-colors"
      >
        <FaClipboardList className="text-blue-500 text-xl" />
        <span className="ml-4 text-lg md:text-xl font-semibold text-gray-800 khmer">ប្រយោជន៏</span>
      </button>

      {/* Conditionally render icon based on accessStatus */}
      <button
        onClick={onSubscriptionClick}
        className="flex items-center justify-start w-full py-2 border-b hover:bg-blue-200 transition-colors"
      >
        <FaStar className="text-yellow-500 text-xl" /> 


        <span className="ml-4 text-lg md:text-xl font-semibold text-gray-800 khmer">
          {accessStatus === 'granted' ? 'គណនី' : 'បង់ប្រាក់'}
        </span>
      </button>

      {/* Group Q&A Button with conditional icon */}
      <button
        onClick={onSubscriptionClick}
        className="flex items-center justify-start w-full pt-2 hover:bg-blue-200 transition-colors"
      >
        {accessStatus === 'granted' ? (
          <FaStar className="text-yellow-500 text-xl" /> // Star icon for premium access
        ) : (
          <FaLock className="text-red-500 text-xl" /> // Lock icon for restricted access
        )}
        <span className="ml-4 text-lg md:text-xl font-semibold text-gray-800 khmer">គ្រុបសួរសំណួរ</span>
      </button>
    </div>
  );
};

export default PremiumParts;
