import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaSync } from 'react-icons/fa';

const VocabExercisePage = () => {
    const [vocabItems, setVocabItems] = useState([]);
    const [answers, setAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [scoreData, setScoreData] = useState(null);
    const { setId } = useParams();
    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
        axios.get(`https://tonletalk-e5cea0c5c5df.herokuapp.com/vocabs/${setId}`, { headers })
            .then(response => {
                if (response.data && response.data.length > 0) {
                    const vocabData = response.data.map(vocabItem => {
                        const distractors = response.data
                            .filter(item => item.VocabID !== vocabItem.VocabID)
                            .sort(() => 0.5 - Math.random())
                            .slice(0, 3)
                            .map(item => item.Definition);
                        const options = shuffle([vocabItem.Definition, ...distractors]);
                        return {
                            ...vocabItem,
                            options,
                        };
                    });
                    setVocabItems(vocabData);
                } else {
                    console.error('Error: No vocabulary data.');
                }
            })
            .catch(error => {
                console.error('Error fetching vocabularies:', error);
            });
    }, [setId, token]);

    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    };

    const handleOptionSelect = (vocabId, selectedOption) => {
        setAnswers({
            ...answers,
            [vocabId]: selectedOption,
        });
    };

    const handleSubmit = () => {
        calculateScore();
        setSubmitted(true);
    };

    const calculateScore = async () => {
        let correctAnswersCount = vocabItems.reduce((acc, vocabItem) => {
            return acc + (answers[vocabItem.VocabID] === vocabItem.Definition ? 1 : 0);
        }, 0);

        const percentage = (correctAnswersCount / vocabItems.length) * 100;

        const scoreData = {
            correctAnswersCount,
            total: vocabItems.length,
            percentage,
        };

        setScoreData(scoreData);

        try {
            const response = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/vocabs/scores', { setID: setId, score: percentage }, { headers });
            console.log('Score saved successfully:', response.data);
        } catch (error) {
            console.error('Error saving score:', error.message);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 pt-24 khmer pb-24 px-4 lg:pt-32 ">
            <h1 className='font-bold text-3xl mb-5'>Exercise</h1>
            <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'>
                {vocabItems.length > 0 && vocabItems.map((vocabItem, index) => (
                    <div key={index} className="bg-white p-6 rounded-lg shadow space-y-4 mb-6 w-full">
                        <h2 className="text-xl font-semibold">What is the definition of {vocabItem.Term}?</h2>
                        <ul className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            {vocabItem.options.map((option, optionIndex) => (
                                <li
                                    key={optionIndex}
                                    onClick={() => handleOptionSelect(vocabItem.VocabID, option)}
                                    className={`p-4 rounded-md shadow cursor-pointer transition-colors ${
                                        answers[vocabItem.VocabID] === option
                                            ? 'bg-blue-100' : 'bg-gray-100 hover:bg-gray-200'
                                    }`}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {submitted && (
                            <div className={`text-lg font-semibold ${
                                answers[vocabItem.VocabID] === vocabItem.Definition ? 'text-green-600' : 'text-red-600'
                            }`}>
                                {answers[vocabItem.VocabID] === vocabItem.Definition ? 'Correct!' : 'Incorrect!'}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {!submitted && (
                <button
                    onClick={handleSubmit}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition duration-300">
                    Submit
                </button>
            )}

            {/* Modal */}
            {submitted && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                        <h3 className="text-2xl font-bold mb-4 text-green-500">Results</h3>
                        <p className="text-lg mb-4">You scored {scoreData.correctAnswersCount} out of {scoreData.total}, which is <span className={`${scoreData.percentage && scoreData.percentage.toFixed(2) > 50.00 ? 'text-green-500' : 'text-red-600'}`}>{scoreData.percentage && scoreData.percentage.toFixed(2)}%</span>.</p>
                        <div className="flex justify-between">
                          <button onClick={handleBack} className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300 flex items-center">
                              <FaArrowLeft className="mr-2" /> Back
                          </button>
                          <button onClick={handleRefresh} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300 flex items-center">
                              <FaSync className="mr-2" /> Refresh
                          </button>
                      </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VocabExercisePage;
