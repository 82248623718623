import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export default function FAQSection() {
  const [faqs, setFaqs] = useState([
    { question: 'តើខ្ញុំបង់លុយធ្វើដូចម្តេច?', answer: 'បងអាចបង់លុយនៅក្នុង website ជាមួយកាត ឬទាក់ទងមក telegram ដើម្បីគិតលុយតាម ABA, WING, ACELEDA...', isOpen: false },
    { question: 'តើ Tonle Talk មានអ្វីខ្លះ?', answer: 'Tonle Talk គឺមានឯកសារបង្រៀនដោយអានពី 0 បូកនឹង Grammar, Vocabs, និងច្រើនទៀត! ហើយក៏មានជាលក្ខណៈវីឌេអូឆាប់ៗ', isOpen: false },
    { question: 'តើ Tonle Talk មានបង្រៀនជាវីឌេអូទេ?', answer: 'មានបង ហើយនឹងចេញនៅចុងខែ 03 នេះហើយ!', isOpen: false },
    { question: 'តើ Tonle Talk គិតលុយទេ?', answer: 'Tonle Talk គឺ FREE 7 ថ្ងៃដំបូង ហើយគឺនឹងគិត 2.99$/ខែក្រោយមក តម្លៃកាហ្វេមួយកែវ', isOpen: false },
    { question: 'តើ Tonle Talk មានគ្រូសម្រាប់ជួយឆ្លើយសំណួរទេ?', answer: 'មាន ក្រោយចុះឈ្មោះ បងអាចចុចលើ "គ្រុបជំនួយ" នៅខាងលើដើម្បីចូល telegram group!', isOpen: false }
  ]);

  const toggleFAQ = index => {
    setFaqs(prevFaqs => 
      prevFaqs.map((faq, i) => 
        i === index ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  return (
    <section className="py-8">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-4xl">
        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center  khmer">សំណួរដែលសួរញឹកញាប់</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <motion.div 
              key={index} 
              className="bg-white rounded-2xl shadow-lg overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <button
                className="w-full text-left p-6 focus:outline-none"
                onClick={() => toggleFAQ(index)}
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg md:text-xl font-semibold  khmer">{faq.question}</h3>
                  <span className={`text-cyan-500 transition-transform duration-300 transform ${faq.isOpen ? 'rotate-180' : ''}`}>
                    <FaChevronDown />
                  </span>
                </div>
              </button>
              <AnimatePresence>
                {faq.isOpen && (
                  <motion.div 
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="px-6 pb-6"
                  >
                    <p className="text-gray-600 khmer">{faq.answer}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}