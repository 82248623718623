import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from '../../components/Modal';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import LearningHero from '../../components/LearningHero';

const AllLearningPage = ({ accessStatus }) => {
    const [documents, setDocuments] = useState([]);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [filter, setFilter] = useState('all');
    const [showLoginModal, setShowLoginModal] = useState(false);

    const token = localStorage.getItem('token');

    useEffect(() => {
        axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/get-react-app/')
            .then(response => {
                setDocuments(response.data);
                setFilteredDocuments(response.data);
            });
    }, []);

    const navigate = useNavigate();

    const handleNav = (documentName, criteria) => {
        if (accessStatus === null) {
            setShowLoginModal(true);
        } else if (accessStatus) {
            navigate(`/learnDocuments/${documentName}/${criteria}`);
        }
    };

    const handleFilterChange = (filter) => {
        setFilter(filter);
        if (filter === 'all') {
            setFilteredDocuments(documents);
        } else {
            setFilteredDocuments(documents.filter(doc => doc.criteria === filter));
        }
    };

    const DocumentsMapping = ({ arr }) => {
        return (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {arr.map((document, index) => (
                    <div
                        key={index}
                        onClick={() => handleNav(document.document_name, document.criteria)}
                        className="p-6 border border-black bg-gradient-to-r from-blue-400 to-teal-500 rounded-lg shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:scale-105 flex flex-col justify-between cursor-pointer"
                    >
                        <div>
                            {/* Category Label */}
                            <span
                                className={`text-sm font-semibold text-white uppercase mb-2 px-2 py-1 rounded-full ${
                                    document.criteria === 'grammar' ? 'bg-red-500' : document.criteria === 'beginner' ? 'bg-green-500' : 'bg-yellow-500'
                                }`}
                            >
                                {document.criteria === 'grammar' ? 'Grammar' : document.criteria === 'beginner' ? 'Beginner' : 'Vocabulary'}
                            </span>
    
                            <h2 className="text-2xl font-bold text-white mb-2">{document.title}</h2>
                            <p className="text-md text-gray-100 opacity-90 mb-4 khmer">{document.description}</p>
                        </div>
                        {!token && (
                            <div className="flex justify-end">
                                <FaLock className="text-white" />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };    

    return (
        <div className="flex flex-col h-full  bg-gradient-to-r from-purple-500 to-blue-500 pt-24 pb-12 sm:pt-16 bg-white">
            <Outlet />
            <LearningHero />

            {/* Filter Section */}
            <div className="bg-gray-100 py-6 ">
                <div className="container mx-auto flex justify-center space-x-4">
                    <button
                        onClick={() => handleFilterChange('all')}
                        className={`px-4 py-2 font-semibold text-black rounded-full transition duration-300 ${filter === 'all' ? 'bg-white text-purple-500' : 'hover:bg-purple-700'}`}
                    >
                        All
                    </button>
                    <button
                        onClick={() => handleFilterChange('grammar')}
                        className={`px-4 py-2 font-semibold text-black rounded-full transition duration-300 ${filter === 'grammar' ? 'bg-white text-purple-500' : 'hover:bg-purple-700'}`}
                    >
                        Grammar
                    </button>
                    <button
                        onClick={() => handleFilterChange('beginner')}
                        className={`px-4 py-2 font-semibold text-black rounded-full transition duration-300 ${filter === 'beginner' ? 'bg-white text-purple-500' : 'hover:bg-purple-700'}`}
                    >
                        Beginner
                    </button>
                    <button
                        onClick={() => handleFilterChange('vocab')}
                        className={`px-4 py-2 font-semibold text-black rounded-full transition duration-300 ${filter === 'vocab' ? 'bg-white text-purple-500' : 'hover:bg-purple-700'}`}
                    >
                        Vocabulary
                    </button>
                </div>
            </div>

            {/* Document Display Section */}
            <div className="mx-auto w-full px-6 pb-10 bg-gray-100">
                <DocumentsMapping arr={filteredDocuments} />
            </div>

            {/* Modal for Login */}
            {showLoginModal && (
                <Modal onClose={() => setShowLoginModal(false)} title="Oops! Please login!">
                    <div className="bg-white p-4 py-0 rounded-md">
                        <p className="text-gray-600 mb-4 khmer">
                            បងសុំ LOGIN សិនហើយបង់ PREMIUM ដើម្បីរៀន! មាន FREE 7 ថ្ងៃ
                        </p>
                        <div className="flex justify-center">
                            <button
                                onClick={() => { navigate('/login'); }}
                                className="flex justify-center items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                            >
                                Login <FaLockOpen className="ml-2" />
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default AllLearningPage;
