import DocumentPreview from "../DocumentPreview";
import { TbCheck, TbProgressCheck } from 'react-icons/tb';

export default function CurrentlyLeraningCard({doc, index, handleDocumentPress, getBackgroundColor, accessStatus}) {
    return (
    <div
    key={doc.DocumentID || index}
    className={`rounded-md border-2 overflow-hidden cursor-pointer ${getBackgroundColor(
        doc.LevelName
    )}`}
    onClick={() => handleDocumentPress(doc.DocumentID, doc.Free)}
>

    <div className="p-4">
        <DocumentPreview doc={doc} accessStatus={accessStatus} />
        {/* You can add more colorful elements inside each DocumentPreview if needed */}
    </div>
    <div className="bg-white py-2 flex  flex-row justify-between items-center px-2">
        <p className="text-sm text-gray-500 khmer">
            កំរិត: {doc.LevelName}

        </p>
        {doc.Completed === 0 || doc.Completed === 1 ? (
                <>
                    {doc.Completed ? <TbCheck className='ml-2' size={20} color='green'/> : <TbProgressCheck className='ml-2' size={20} color='gray'/>}
                </>
            ) : null}
    </div>
</div>
)
}