import React from 'react';

const VocabHero = () => {
    return (
        <div className="hero bg-gradient-to-r from-blue-100 to-indigo-100 md:h-48 lg:h-80  h-32 p-4 flex items-center justify-center">
            <div className="p-4 rounded-lg text-center">
                <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-black mb-6 drop-shadow-md animate-pulse khmer">
                    រៀនពាក្យថ្មីៗ
                </h1>
                <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl text-black mb-4 animate-bounce khmer">
                    ដោយប្រើ flash cards និង exercise
                </p>
            </div>
        </div>
    );
};

export default VocabHero;
