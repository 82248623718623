import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PromptPreview from '../../components/PromptPreview';
import Loading from '../../components/Loading';
import WritingBanner from '../../components/WritingBanner';
import Modal from '../../components/Modal';
import { FaLockOpen } from 'react-icons/fa';

export default function AllWritingPage({ accessStatus }) {
  const [prompts, setPrompts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  }


  const fetchNoAccess = async () => {
    axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/write/all')
    .then((response) => {
      setPrompts(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching prompts:', error);
      setLoading(false);
    });
  }

  const fetchAccess = async () => {
    axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/write/allDocs', {headers})
    .then((response) => {
      setPrompts(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching prompts:', error);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    if (token) {
      fetchAccess();
    } else {
      fetchNoAccess();
    }
  }, []);

  // Populate categories and levels from prompts data
  useEffect(() => {
    const uniqueCategories = Array.from(new Set(prompts.map((prompt) => prompt.CategoryName)));
    setCategories(uniqueCategories);
    
    const uniqueLevels = Array.from(new Set(prompts.map((prompt) => prompt.LevelName)));
    setLevels(uniqueLevels);
  }, [prompts]);

  const handlePromptClick = (promptId, prompt) => {
    console.log(prompt)
    if ((accessStatus === 'granted' || prompt.free) && token) {
        navigate(`/writing/${promptId}`);
    } else if (!token) {
        setShowLogin(true);
    }
    else {
        setShowModal(true);
    }
};


  const filteredPrompts = prompts.filter((prompt) => {
    return (
      prompt.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (categoryFilter === '' || prompt.CategoryName === categoryFilter) &&
      (levelFilter === '' || prompt.LevelName === levelFilter)
    );
  });
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col h-full lg:pt-20 md:pt-20 pt-16 pb-12 sm:pt-16 bg-gradient-to-r from-blue-800 to-purple-700">
      <WritingBanner/>
      <div className="px-4 bg-gray-100">
        <div className="py-4 flex flex-col sm:flex-row gap-4">
          {/* Search input */}
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-300 p-2 rounded shadow-sm w-full sm:w-2/3"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="flex flex-row gap-4 w-full sm:w-1/3">
            {/* Category filter */}
            <select
              className="border border-gray-300 p-2 rounded shadow-sm whitespace-nowrap w-full"
              onChange={(e) => setCategoryFilter(e.target.value)}
              value={categoryFilter}
            >
              <option value="">All Categories</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
            {/* Level filter */}
            <select
              className="border border-gray-300 p-2 rounded shadow-sm whitespace-nowrap w-full"
              onChange={(e) => setLevelFilter(e.target.value)}
              value={levelFilter}
            >
              <option value="">All Levels</option>
              {levels.map((level, index) => (
                <option key={index} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </div>
        </div>

        {showModal && (
          <Modal onClose={() => setShowModal(false)} title='Oops! Access Denied'>
            <div className="bg-white p-4 py-0 rounded-md">
              <p className="text-gray-600 mb-4 khmer text-lg">ដើម្បីចូលទៅកាន់ឯកសារនេះ អ្នកត្រូវ subscribe.</p>
              <div className='flex justify-center'>
                <button onClick={() => {navigate('/account')}} className="flex justify-center items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
                  Subscribe Now <FaLockOpen className='ml-2'/>
                </button>
              </div>
            </div>
          </Modal>
        )}

      {showLogin && (
          <Modal onClose={() => setShowLogin(false)} title='Oops! Access Denied'>
            <div className="bg-white p-4 py-0 rounded-md">
              <p className="text-gray-600 mb-4 khmer text-lg">ដើម្បីចូលទៅកាន់ឯកសារនេះ អ្នកត្រូវ login.</p>
              <div className='flex justify-center'>
                <button onClick={() => {navigate('/login')}} className="flex justify-center items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
                  Login Now <FaLockOpen className='ml-2'/>
                </button>
              </div>
            </div>
          </Modal>
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <div className="grid grid-cols-1 gap-4 overflow-auto pb-10">
            {filteredPrompts.map((prompt) => (
              <div
                key={prompt.id}
                className="cursor-pointer"
                onClick={() => handlePromptClick(prompt.id, prompt)}
              >
                <PromptPreview prompt={prompt} accessStatus={accessStatus} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
