import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Modal from '../components/Modal';
import { useNavigate } from 'react-router-dom';
import { FaLockOpen } from 'react-icons/fa6';
import Loading from '../components/Loading';
import { FaTelegram } from 'react-icons/fa';

const VideoPage = ({ accessStatus }) => {
  const [showCourses, setShowCourses] = useState(true);
  const [courses, setCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState(null);
  const videoRef = useRef(null);
  const saveIntervalRef = useRef(null);
  const [userProgress, setUserProgress] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // Setting axios defaults for easier request management
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  axios.defaults.baseURL = 'https://tonletalk-e5cea0c5c5df.herokuapp.com';

  useEffect(() => {
    setLoading(true);

    if (!token) {
      setShowLoginModal(true);
      setLoading(false);
      return; // Exit the useEffect if there's no token
    }

    // Always attempt to fetch courses first
    axios
      .get('/course')
      .then((coursesResponse) => {
        const coursesData = coursesResponse.data;
        setCourses(coursesData);

        // If courses were fetched successfully, attempt to fetch user progress
        if (token) {
          axios
            .get('/course/user-video-progress')
            .then((progressResponse) => {
              const userProgressData = progressResponse.data;
              setUserProgress(userProgressData);

              // Determine active course based on user progress or default to the first course
              const progressCourse = userProgressData
                ? coursesData.find((course) => course.id === userProgressData.CurrentEpisode)
                : null;
              const initialCourseId = progressCourse ? progressCourse.id : coursesData[0]?.id;

              // Fetch details for the determined initial course
              if (initialCourseId) {
                fetchCourseDetail(initialCourseId);
              }

              setLoading(false);
            })
            .catch((error) => {
              console.log('Error fetching user progress:', error);
              const initialCourseId = coursesData[0]?.id;
              if (initialCourseId) {
                fetchCourseDetail(initialCourseId);
              }
              setLoading(false);
            });
        } else {
          // Default to the first course
          const initialCourseId = coursesData[0]?.id;
          if (initialCourseId) {
            fetchCourseDetail(initialCourseId);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log('Error fetching courses:', error);
        setLoading(false);
      });
  }, []);

  const fetchCourseDetail = (courseId) => {
    if (!token) {
      setShowLoginModal(true);
      return;
    }

    if (accessStatus === 'revoked') {
      setShowModal(true);
      return;
    }

    axios
      .get(`/course/url/${courseId}`)
      .then((response) => {
        const { videoUrl, content, title } = response.data;
        setActiveCourse((prevCourse) => ({ ...prevCourse, id: courseId, videoUrl, content, title }));

        // Once the videoUrl is updated, set the video time to 0 when metadata loads
        if (videoRef.current) {
          videoRef.current.addEventListener('loadedmetadata', () => {
            videoRef.current.currentTime = 0; // Reset time to 0
          });
        }
      })
      .catch((error) => {
        console.log('Error fetching course detail:', error);
      });
  };

  useEffect(() => {
    if (!userProgress || !courses.length) return;

    // Find the course from the userProgress
    const course = courses.find((course) => course.id === userProgress.CurrentEpisode);
    if (!course) {
      console.log('No matching course found for user progress');
      return;
    }

    setActiveCourse(course); // Set the active course based on user progress

    // Listen for the video metadata being loaded, and then set the currentTime
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', () => {
        videoElement.currentTime = userProgress.CurrentTime; // Seek to user's last watched position
      });
    }
  }, [userProgress, courses]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const startSavingProgress = () => {
      if (saveIntervalRef.current) clearInterval(saveIntervalRef.current);
      saveIntervalRef.current = setInterval(() => {
        saveProgress();
      }, 15000); // Save progress every 15 seconds
    };

    const stopSavingProgress = () => {
      if (saveIntervalRef.current) clearInterval(saveIntervalRef.current);
    };

    videoElement.addEventListener('play', startSavingProgress);
    videoElement.addEventListener('pause', stopSavingProgress);
    videoElement.addEventListener('ended', stopSavingProgress); // Stop saving when video ends

    return () => {
      videoElement.removeEventListener('play', startSavingProgress);
      videoElement.removeEventListener('pause', stopSavingProgress);
      videoElement.removeEventListener('ended', stopSavingProgress);
    };
  }, [activeCourse]); // Re-apply effect when activeCourse changes

  const saveProgress = () => {
    if (!videoRef.current || !activeCourse) return;

    const currentTime = videoRef.current.currentTime;
    const currentEpisode = activeCourse.id;

    axios
      .post('/course/save-video-progress', { currentEpisode, currentTime }, { headers })
      .catch((error) => console.error('Error saving progress:', error));
  };

  const handleCourseClick = (course) => {
    setActiveCourse(course);
    fetchCourseDetail(course.id);

    // Reset video to 0 when a new video is played
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col lg:flex-row w-full pb-16 bg-gray-900">
      <div className="flex-grow bg-white shadow-md lg:mr-4 lg:mb-0">
        {activeCourse && (
          <>
            <div className="aspect-w-16 aspect-h-9 bg-gray-200">
              <video
                ref={videoRef}
                src={token ? activeCourse.videoUrl : 'https://tonletalk.s3.us-east-005.backblazeb2.com/video-placeholder.mp4'}
                autoPlay
                controls
                className="object-cover w-full h-full"
              />
            </div>
            <div className="p-4 w-auto">
              <h1 className="text-2xl font-semibold mb-2">{activeCourse.title}</h1>
              <p className="text-gray-600 ml-4">{activeCourse.content}</p>
            </div>
          </>
        )}
      </div>

      <div className={`w-full lg:w-1/4 bg-gray-900 pt-4 md:pt-4 md:p-0 p-4 text-white ${showCourses ? 'h-auto overflow-y' : 'h-screen overflow-y-scroll'}`}>
        <a
          className="cursor-pointer text-lg font-semibold text-blue-500 hover:text-blue-600 mb-4"
          href={accessStatus === 'granted' ? 'https://t.me/+mZJOg36XcfU4YmE1' : '#'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTelegram className="inline-block mr-2" />
          Telegram Link
        </a>
        <button onClick={() => setShowCourses(!showCourses)} className="text-lg font-semibold mb-4 mt-2 items-center flex">
          Courses
          <div className="ml-1">
            {showCourses ? <FiChevronUp className="cursor-pointer" /> : <FiChevronDown className="cursor-pointer" />}
          </div>
        </button>
        <ul>
          {courses.map((course) => (
            <li
              key={course.id}
              className={`p-2 mb-2 cursor-pointer border-b border-white ${
                activeCourse && activeCourse.id === course.id ? 'bg-gray-700' : 'hover:bg-gray-700'
              }`}
              onClick={() => handleCourseClick(course)}
            >
              {course.title}
            </li>
          ))}
        </ul>
      </div>

      {showLoginModal && (
        <Modal onClose={() => setShowLoginModal(false)} title="Oops! Please login!">
          <div className="bg-white p-4 py-0 rounded-md">
            <p className="text-gray-600 mb-4 khmer">Please LOGIN to access this course!</p>
            <div className="flex justify-center">
              <button onClick={() => navigate('/login')} className="flex justify-center items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
                Login <FaLockOpen className="ml-2" />
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showModal && (
        <Modal onClose={() => setShowModal(false)} title="Oops! Please Subscribe!">
          <div className="bg-white p-4 py-0 rounded-md">
            <p className="text-gray-600 mb-4 khmer">You need a PREMIUM account to access this content.</p>
            <div className="flex justify-center">
              <button onClick={() => navigate('/account')} className="flex justify-center items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300">
                Go Premium <FaLockOpen className="ml-2" />
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default VideoPage;
