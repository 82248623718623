import React from 'react';

const SearchBar = ({ searchQuery, handleSearch }) => {
  return (
    <div className="my-4">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search flashcard sets..."
        className="w-full p-3 border border-gray-300 rounded-md"
      />
    </div>
  );
};

export default SearchBar;
