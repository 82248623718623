const Answer = ({ text, onSelect, selected, isCorrect, showResult, userAnswerCorrect }) => {
    let backgroundColor = 'bg-gray-100';
    let textDecoration = '';
  
    if (showResult) {
      if (selected) {
        backgroundColor = userAnswerCorrect ? 'bg-green-400' : 'bg-red-500';
      } else if (isCorrect) {
        backgroundColor = 'bg-green-200';
      }
      textDecoration = selected && !userAnswerCorrect ? 'line-through' : '';
    } else if (selected) {
      backgroundColor = 'bg-blue-200';
    }
    
    return (
      <button onClick={onSelect} className={`flex items-start my-1 px-2 py-1 answer-card rounded ${backgroundColor} hover:bg-gray-200  transition`}>
        <p className={`text-md text-left ${textDecoration}`}>{text}</p>
      </button>
    );
  };

  export default Answer;