import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { FaLockOpen, FaStar, FaBookOpen, FaChalkboardTeacher, FaPenAlt, FaApple, FaAndroid, FaDownload, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import Hero from '../../components/Hero';
import Testimonial from '../../components/Testimonials';
import FAQSection from '../../components/FAQSection';
import Feedback from '../../components/Feedback';
import FeaturesSection from '../../components/HomePage/FeaturesSection';
import LoggedOutPremiumSection from '../../components/HomePage/LoggedOutPremiumSection';
import FreeTrial from '../../components/HomePage/FreeTrial';
import DownloadAppSection from '../../components/HomePage/DownloadAppSection';

export default function LoggedOutpage({ cta, handleCta, accessStatus }) {
  const navigate = useNavigate();

  const scrollToSection = () => {
    const section = document.querySelector('.FreeTrial');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
  <div className={`pb-12 flex flex-col h-full  `}>      
    <Outlet />      
      <Hero 
        scrollToSection={scrollToSection}
      />
      
      <main className="container mx-auto px-4 py-12">



        {/* How It Works Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 khmer">របៀបដែល Tonle Talk ដំណើរការ</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="bg-blue-100 rounded-full p-6 inline-block mb-4">
                <FaBookOpen className="text-4xl text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2 khmer">រៀនតាមល្បឿនរបស់អ្នក</h3>
              <p className="text-gray-600 khmer">មេរៀនខ្លីៗ និងងាយស្រួលតាមដាន សម្រាប់ការរៀនសូត្រប្រចាំថ្ងៃ</p>
            </div>
            <div className="text-center">
              <div className="bg-green-100 rounded-full p-6 inline-block mb-4">
                <FaChalkboardTeacher className="text-4xl text-green-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2 khmer">អនុវត្តជាមួយលំហាត់អន្តរកម្ម</h3>
              <p className="text-gray-600 khmer">បង្កើនជំនាញរបស់អ្នកជាមួយនឹងលំហាត់ និងការសាកល្បងជាក់ស្តែង</p>
            </div>
            <div className="text-center">
              <div className="bg-purple-100 rounded-full p-6 inline-block mb-4">
                <FaPenAlt className="text-4xl text-purple-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2 khmer">តាមដានវឌ្ឍនភាពរបស់អ្នក</h3>
              <p className="text-gray-600 khmer">មើលការរីកចម្រើនរបស់អ្នក និងទទួលបានរង្វាន់សម្រាប់សមិទ្ធផលរបស់អ្នក</p>
            </div>
          </div>
        </section>


        {/* Call to Action Section */}
        <section className="FreeTrial">
          <FreeTrial 
            cta={cta}
            handleCta={handleCta}
            accessStatus={accessStatus}
          />
        </section>

        {/* Testimonials Section */}
        <section className="my-16  ">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800 khmer">អ្វីដែលសិស្សរបស់យើងនិយាយ</h2>
          <Testimonial />
        </section>

        {/* Download App Section */}
        <DownloadAppSection />

        {/* FAQ Section */}
        <FAQSection />

        {accessStatus === 'granted' && (
          <section className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-lg shadow-xl p-8 mt-16">
            <h2 className="text-3xl font-bold text-white mb-4 text-center khmer">មតិកែលម្អ</h2>
            <p className="text-xl text-blue-100 mb-6 text-center khmer">ចុចប៊ូតុងខាងក្រោមហើយបច្ចេកមតិ</p>
            <div className="flex justify-center">
              <Feedback />
            </div>
            <p className="mt-6 text-center text-blue-100 khmer">មតិអ្នកគឺសំខាន់ដើម្បីធ្វើឲ្យ Tonle Talk កាន់តែល្អ! សង់សម្រាប់ខ្មែរ សង់ដោយខ្មែរ!</p>
          </section>
        )}
      </main>
    </div>
  );
}