import React, { createContext, useState } from "react";

import RouteApp from "./route";
import SaveScreenDimensions from "./components/SaveScreenDimensions";

// export const ModeContext = createContext(null);


export default function App() {


  return (
    <>
        <RouteApp/>
        <SaveScreenDimensions />

    </>
  );
}