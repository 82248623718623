import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../components/Loading';
import { FaTelegramPlane } from "react-icons/fa";

const Subscribe = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { plan } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false); // Track if payment is being submitted
  const [waiting, setWaiting] = useState(false);
  const [paymentOption, setPaymentOption] = useState('payNow'); // Track payment option selection

  let planObj;

  

  if (plan === '1-month') {
    planObj = {
      name: '1 month',
      price: '2.99 / month',
      duration: 30,
      priceId: 'price_1Oh42jImouIeU5G8ganPSG6h'
    };
  } else if (plan == '3-months') {
    planObj = {
      name: '3 months',
      price: '4.99 / 3 month',
      duration: 90,
      priceId: 'price_1Oh42uImouIeU5G8ySa3Tbsf'
    };
  } else if (plan == '1-year') {
    planObj = {
      name: '1 year',
      price: '14.99 / year',
      duration: 365,
      priceId: 'price_1Oh434ImouIeU5G8ZFevPiRd'
    };
  }

  const navigate = useNavigate();

  const navigateToAccount = () => {
    setWaiting(true);
    setTimeout(() => {
      setWaiting(false);
      navigate('/account');
      window.location.reload();
    }, 3000);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
  
    setIsSubmitting(true); // Start submitting payment
  
    try {
      const token = localStorage.getItem('token'); // Get the token from local storage
  
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      let clientSecret, email;
  
      // If "payNow" option is selected, create a payment intent
      if (paymentOption === 'payNow') {
        const paymentIntentResponse = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/subscription/create-subscription', {
          priceId: planObj.priceId,
          paymentOption: paymentOption
        }, { headers });
  
        clientSecret = paymentIntentResponse.data.clientSecret;
        email = paymentIntentResponse.data.email;
  
        if (!stripe || !elements) {
          toast.error('Stripe is not initialized. Please try again.');
          return;
        }
  
        // Confirm the card payment with the client secret
        const paymentResult = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              email: email,
            },
          },
        });
  
        if (paymentResult.error) {
          toast.error('Payment failed. Please try again.');
          return;
        }
      } else if (paymentOption === 'freeTrial') {
        // Assuming you've received the Setup Intent's client secret from your backend
        const setupIntentResponse = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/subscription/create-setup-intent', {
          // You might not need to pass priceId for a free trial, but this depends on your backend logic
          paymentOption: paymentOption
        }, { headers });

        const clientSecret = setupIntentResponse.data.clientSecret;
        const email = setupIntentResponse.data.email;

        if (!stripe || !elements) {
          toast.error('Stripe is not initialized. Please try again.');
          return;
        }

        // Confirm the card setup with the client secret
        const setupResult = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              // Assuming you have or want to capture the user's email or other billing details here
            },
          },
        });

        if (setupResult.error) {
          toast.error(`Setup failed: ${setupResult.error.message}`);
          return;
        } else {
          // The payment method has been successfully attached to the customer
          const paymentMethodId = setupResult.setupIntent.payment_method;
          // If "freeTrial" option is selected, directly initiate a free trial without payment
          const fetchFree = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/subscription/create-subscription', {
            priceId: planObj.priceId,
            paymentOption: paymentOption,
            paymentMethodId: paymentMethodId
          }, { headers });
    
          // Optionally, handle response for free trial initiation
          console.log(fetchFree.data)
        }


      }
  
      navigateToAccount();
  
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.error);
      
    } finally {
      setIsSubmitting(false); // Stop submitting payment
    }
  };
  

  if (!waiting) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="max-w-md bg-white p-6 rounded-lg shadow-md">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Upgrade Your Plan</h1>
          <p className="text-gray-600 mb-4">
            <span className='font-bold'>{planObj.name}</span> plan. ${planObj.price}
          </p>
          <p className="text-gray-600 text-lg mb-2 khmer">សូមបញ្ចូលព័ត៌មានទូទាត់ប្រាក់របស់អ្នកខាងក្រោម</p>

                  {/* Radio buttons for payment options */}
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="payNow"
              checked={paymentOption === 'payNow'}
              onChange={() => setPaymentOption('payNow')}
              className="form-radio h-4 w-4 text-blue-500"
            />
            <span className="ml-2 text-gray-700 khmer">បង់ឥឡូវ</span>
          </label>
          <label className="inline-flex items-center ml-6">
            <input
              type="radio"
              value="freeTrial"
              checked={paymentOption === 'freeTrial'}
              onChange={() => setPaymentOption('freeTrial')}
              className="form-radio h-4 w-4 text-blue-500"
            />
            <span className="ml-2 text-gray-700 khmer">FREE 7 ថ្ងៃ</span>
          </label>
        </div>
  
          <form onSubmit={handleSubscribe}>
            <div id="payment-element">
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#32325d',
                      fontFamily: 'Arial, sans-serif',
                      '::placeholder': {
                        color: '#a0aec0',
                      },
                    },
                  },
                }}
                className='border border-gray-300 p-2 rounded shadow-sm w-full'
              />
            </div>
            <div className='w-full flex flex-col justify-center items-center'>
              <button
                type="submit"
                className={`w-full mt-4 bg-green-500 text-white khmer font-semibold py-2 rounded-2xl hover:bg-green-600 transition duration-300 ${isSubmitting ? 'opacity-70 cursor-wait' : ''}`}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'រងចាំ...' : 'ទូទាត់ប្រាក់'}
              </button>

            </div>
          </form>
          <div className="mt-4 mb-1 text-sm font-bold text-gray-400 text-center khmer">
            កាតនឹងមិនត្រូវយកទៅប្រើប្រាស់ផ្តេសផ្តាសទេ. Cancel anytime.
          </div>
          <div className='flex items-center justify-center'>
            {/* <a href='https://t.me/tonletalk' className="text-sm text-blue-500 mt-1 py-1 px-2 rounded-xl hover:opacity-70 transition cursor-pointer text-center"> */}

            <a href='https://t.me/tonletalklearning' className="bg-blue-500 text-sm text-white mt-1 py-1 px-2 rounded-xl hover:opacity-70 transition cursor-pointer text-center khmer">
              ទូទាត់តាម telegram <FaTelegramPlane className='inline-block'/>
            </a>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Loading/>
    )
  }

};

export default Subscribe;