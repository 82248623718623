import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements } from '@stripe/react-stripe-js';
import { useElements } from '@stripe/react-stripe-js';
import stripePromise from '../components/StripePromise';
import Subscribe from '../functions/Subscribe';

const SubscribePage = () => {
  return(
    <Elements stripe={stripePromise}>
      <Subscribe />
    </Elements>
  )
};

export default SubscribePage;
