import React, { useEffect, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { useParams } from 'react-router-dom';

const LearningDocuments = () => {
    const [htmlContent, setHtmlContent] = useState('');
    const { documentName } = useParams();
    const { criteria } = useParams();


    const token = localStorage.getItem('token');
    const headers = {
        Authorization: `Bearer ${token}`
    };

    useEffect(() => {
        fetch(`https://tonletalk-e5cea0c5c5df.herokuapp.com/get-react-app/${documentName}/${criteria}`, { headers } )
            .then(response => response.text())
            .then(data => {
                setHtmlContent(data);
            })
            .catch(error => {
                console.error('Error fetching HTML file:', error);
            });
    }, []);

    return (
        <>
            {htmlContent && <InnerHTML html={htmlContent} />}
        </>
    );
};

export default LearningDocuments;
