import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaTrash, FaStar, FaBook } from "react-icons/fa";

const FavoritedWords = ({ vocabs, deleteWordFromFavorites, deleted }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <motion.div 
      className="mt-10"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="flex items-center mb-6">
        <FaStar className="lg:text-xl text-lg text-yellow-500 mr-4" />
        <h2 className="lg:text-xl text-lg font-bold text-gray-800">Favorited Words</h2>
      </div>
      {vocabs.length === 0 ? (
        <motion.div 
          className='flex flex-col items-center justify-center bg-white rounded-lg shadow-md p-8'
          variants={itemVariants}
        >
          <FaBook className="text-5xl text-gray-300 mb-4" />
          <p className="text-center lg:text-lg text-base text-gray-500 khmer mb-6">
            មិនទាន់មានពាក្យទេ
          </p>
          <button className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300">
            Start Adding Words
          </button>
        </motion.div>
      ) : (
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
          variants={containerVariants}
        >
          {vocabs.map((vocab, index) => (
            <motion.div 
              key={index} 
              className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
              variants={itemVariants}
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className='p-4'>
                <div className='flex justify-between items-center mb-3'>
                  <h3 className="lg:text-lg text-base font-semibold text-gray-800">{vocab.Word}</h3>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => deleteWordFromFavorites(vocab.Word)}
                    disabled={deleted}
                    className={`text-base ${deleted ? 'text-gray-400' : 'text-red-500 hover:text-red-600'}`}
                  >
                    <FaTrash />
                  </motion.button>
                </div>
                <p className="lg:text-base text-sm text-gray-600">{vocab.Definition}</p>
              </div>

            </motion.div>
          ))}
        </motion.div>
      )}
      <AnimatePresence>
        {deleted && (
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="fixed bottom-4 right-4 bg-green-500 text-white py-2 px-4 rounded-md shadow-lg"
          >
            <p className="font-bold khmer lg:text-base text-sm">លុបបានជោគជ័យ</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default FavoritedWords;