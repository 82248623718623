import React from 'react';

export default function AccessDenied() {
  return (
    <div className="h-screen flex items-center justify-center bg-black text-red-500 font-mono">
      <div className="p-8 border border-red-500 rounded-lg bg-gray-900">
        <h1 className="text-4xl font-bold mb-4 text-red-500">ACCESS DENIED</h1>
        <p className="text-lg text-white khmer">
          WARNING: ការលួចចូលរបស់អ្នកត្រូវខាង Tonle Talk ចាប់បានហើយ!.
        </p>
        <p className="text-lg text-white mt-4 khmer">
          បង់គ្រាន់តែ $2.99/month នឹងរៀនបានទេ.
        </p>
        <div className="mt-8 text-red-500 text-sm">
          <p>ERROR CODE: <span className="text-yellow-400">0xH4CK3D</span></p>
          <p>IP TRACE: <span className="text-yellow-400">127.0.0.1</span></p>
          <p>SECURITY ALERT: <span className="text-yellow-400">High</span></p>
        </div>
      </div>
    </div>
  );
}
