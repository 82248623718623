import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { HiHome, HiAcademicCap, HiPencil, HiChartBar, HiUser } from "react-icons/hi";

export default function BottomStackNavigator() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const navItems = [
    { icon: HiHome, label: "Home", path: "/" },
    { icon: HiAcademicCap, label: "Learn", path: "/video" },
    { icon: HiPencil, label: "Practice", path: "/practice" },
    { icon: HiChartBar, label: "Progress", path: "/learning", requiresAuth: true },
    { icon: HiUser, label: "Account", path: "/profile" },
  ];

  return (
    <nav className="fixed z-20 bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200">
      <div className="max-w-screen-xl mx-auto">
        <ul className="flex justify-around items-stretch h-16">
          {navItems.map((item, index) => (
            (!item.requiresAuth || (item.requiresAuth && isLoggedIn)) && (
              <motion.li 
                key={index} 
                className="flex-1"
                whileTap={{ scale: 0.95 }}
              >
                <button
                  onClick={() => navigate(item.path)}
                  className={`w-full h-full flex flex-col items-center justify-center relative ${
                    location.pathname === item.path
                      ? 'text-blue-600'
                      : 'text-gray-600 hover:text-blue-600'
                  }`}
                >
                  <motion.div 
                    className="relative z-20 flex flex-col items-center"
                    initial={{ y: 5, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <div className="flex items-center mb-1">
                      <item.icon className="text-2xl" />
                    </div>
                    <span className="text-xs font-medium">{item.label}</span>
                  </motion.div>
                  {location.pathname === item.path && (
                    <motion.div 
                      className="absolute bottom-0 left-0 right-0 h-1 bg-blue-600"
                      layoutId="underline"
                    />
                  )}
                </button>
              </motion.li>
            )
          ))}
        </ul>
      </div>
    </nav>
  );
}