import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import DocumentPage from "./pages/Practice/DocumentPage";
import Header from "./components/Header";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import PricingPage from "./pages/PricingPage";
import SubscribePage from "./pages/SubscribePage";
import axios from "axios";
import AccountPage from "./pages/AccountPage";
import LearningPage from "./pages/LearningPage";
import WritingPage from "./pages/Practice/WritingPage";
import AllReadingPage from "./pages/Practice/AllReadingPage";
import AllWritingPage from "./pages/Practice/AllWritingPage";
import LearningDocuments from "./pages/Practice/LearningDocuments";
import AllLearningPage from "./pages/Practice/AllLearningPage";
import { io } from "socket.io-client";
import socket from "./socket";
import VideoPage from "./pages/VideoPage";
import VocabPage from "./pages/Practice/VocabPage";
import AllVocabs from "./pages/Practice/AllVocabs";
import VocabExercisePage from "./pages/Practice/VocabExercisePage";
import BottomStackNavigator from "./components/BottomStackNavigator";
import PrivacyPolicyScreen from "./pages/Profile/PrivacyPolicyScreen";
import ProfileScreen from "./pages/Profile/ProfileScreen";
import LoggedOutScreen from "./pages/Profile/LoggedOutScreen";
import PracticePage from "./pages/PracticePage";

export default function RouteApp() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userId, setUserId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessStatus, setAccessStatus] = useState(null);
  const [visited, setVisited] = useState(localStorage.getItem('visited'));

  useEffect(() => {
    const headers = { Authorization: `Bearer ${token}` };
    if (token) {
      axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/user/userId', { headers })
        .then((response) => {
          setUserId(response.data.userId);
          setIsAuthenticated(true);
        })
        .catch((error) => {
          setIsAuthenticated(false);
        });
    }
  }, [token]);

  useEffect(() => {
    if (userId) {
      socket.on('userSignIn', (data) => {
        if(userId === data.uid) {
          localStorage.removeItem('token');
          window.location.reload();
        }
      });
    }

    return () => {
      socket.off('userSignIn');
    };
  }, [userId]);

  useEffect(() => {
    if (token) {
      axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/user/verify', { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          setAccessStatus(response.data.user.accessStatus);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  useEffect(() => {
    if (!visited) {
      localStorage.setItem('visited', true);
    }
  }, [visited]);

  return (
    <Router>
      <Routes>
        <Route 
          path="/video" 
          element={
            <>
              <VideoPage accessStatus={accessStatus ? accessStatus : null} />
              <BottomStackNavigator />
            </>
          } 
        />


        <Route
          path="*"
          element={
            <>
              <Header />
              <Routes>
                <Route path="/" element={<HomePage accessStatus={accessStatus ? accessStatus : null} isAuthenticated={isAuthenticated} />} />
                <Route path="/practice" element={<PracticePage />} />
                <Route path="/allDocuments" element={<AllReadingPage accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/document/:documentId" element={<DocumentPage accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/account" element={accessStatus === 'revoked' ? <PricingPage /> : <AccountPage />} />
                <Route path="/pricing" element={!isAuthenticated && <PricingPage />} />
                <Route path="/learning" element={isAuthenticated && <LearningPage accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/allWriting" element={<AllWritingPage accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/writing/:promptId" element={<WritingPage accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/learnDocuments/:documentName/:criteria" element={<LearningDocuments accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/allLearning" element={<AllLearningPage accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/vocab/:setId" element={<VocabPage accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/allVocab" element={<AllVocabs accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/vocabExercise/:setId" element={<VocabExercisePage accessStatus={accessStatus ? accessStatus : null} />} />
                <Route path="/register" element={isAuthenticated ? <Navigate to="/" /> : <RegisterPage />} />
                <Route path="/profile" element={isAuthenticated ? <ProfileScreen /> : <LoggedOutScreen />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
                <Route path="/subscribe/:plan" element={<SubscribePage />} />
                <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <LoginPage />} />
              </Routes>
              <BottomStackNavigator />
            </>
          }
        />
      </Routes>
    </Router>
  );
}