import React from 'react';

const FlashcardSetItem = ({ set, handleSelectSet }) => {
  return (
    <div onClick={() => handleSelectSet(set.SetID)} className="p-6 bg-gradient-to-r from-blue-500 to-teal-500 rounded-lg border border-black hover:scale-105 transition transform hover:bg-gradient-to-bl focus:outline-none">
      <h2 className="text-lg font-bold text-gray-100 truncate">
        {set.SetName}
        {set.Score !== 0 && set.Score && <span className="font-semibold text-lime-400"> {set.Score}%</span>}
      </h2>
      <p className="text-md text-gray-100 text-opacity-80 mt-2 truncate">{set.LevelName}</p>
    </div>
  );
};

export default FlashcardSetItem;
