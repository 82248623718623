import React, { useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { FaLanguage } from "react-icons/fa";

const GradingRubricModal = ({ onClose }) => {
  const [lang, setLang] = useState('en');

  const handleLanguageChange = () => {
    if (lang === 'en') {
      setLang('kh');
    } else {
      setLang('en');
    }
  }

  return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto z-20 flex justify-center items-center ">
        <div className="bg-white rounded shadow-lg p-4 w-full max-w-md">
          <div className="flex justify-between items-center mb-4">
          <button className='flex items-center hover:opacity-60 transition' onClick={handleLanguageChange}>
            <h2 className="text-xl font-semibold mr-2">Grading Rubric</h2>
            <FaLanguage size={24}/>
          </button>

          <RxCross2 size={24} onClick={onClose} className='cursor-pointer'/>
        </div>
        <div>
          {/* Organization */}
          <div className="mb-4">
            <h3 className="font-semibold">Organization:</h3>
            {
              lang === 'en' ?
              <p>Graders assess how well you organize and structure your response. They look for clear introduction, body paragraphs with supporting details, and a conclusion.</p>
              :
              <p className='khmer'>ថ្នាក់រៀនវាយតម្លៃថាតើអ្នករៀបចំ និងរៀបចំការឆ្លើយតបរបស់អ្នកបានល្អប៉ុណ្ណា។ ពួកគេស្វែងរកការណែនាំច្បាស់លាស់ កថាខណ្ឌតួដែលមានព័ត៌មានលម្អិតជំនួយ និងការសន្និដ្ឋាន។</p>
            }
          </div>
          
          {/* Development and Support */}
          <div className="mb-4">
            <h3 className="font-semibold">Development and Support:</h3>
            {
               lang === 'en' ?
               <p>This criterion evaluates the depth and relevance of your ideas. Graders check if you provide sufficient supporting details, examples, and explanations to develop your main points.</p>
               :
               <p className='khmer'>លក្ខណៈវិនិច្ឆ័យនេះវាយតម្លៃជម្រៅ និងភាពពាក់ព័ន្ធនៃគំនិតរបស់អ្នក។ ថ្នាក់រៀនពិនិត្យមើលថាតើអ្នកផ្តល់ព័ត៌មានលម្អិត ជំនួយគ្រប់គ្រាន់ ឧទាហរណ៍ និងការពន្យល់ដើម្បីអភិវឌ្ឍចំណុចសំខាន់របស់អ្នក។</p>
            }
          </div>
          
          {/* Language Use */}
          <div className="mb-4">
            <h3 className="font-semibold">Language Use:</h3>
            {
              lang === 'en' ?
              <p>Grading in this area focuses on your proficiency in English grammar, vocabulary, and sentence structure. Graders look for varied and accurate language use, appropriate word choice, and effective transitions between ideas.</p>
              :
              <p className='khmer'>ការចាត់ថ្នាក់នៅក្នុងតំបន់នេះផ្តោតលើជំនាញរបស់អ្នកនៅក្នុងវេយ្យាករណ៍ភាសាអង់គ្លេស វាក្យសព្ទ និងរចនាសម្ព័ន្ធប្រយោគ។ ថ្នាក់រៀនស្វែងរកការប្រើប្រាស់ភាសាខុសៗគ្នា និងត្រឹមត្រូវ ជម្រើសពាក្យសមរម្យ និងការផ្លាស់ប្តូរប្រកបដោយប្រសិទ្ធភាពរវាងគំនិត។</p>
            }
          </div>
          
          {/* Mechanics */}
          <div>
            <h3 className="font-semibold">Mechanics:</h3>
            {
              lang === 'en' ?
                <p>This criterion assesses your ability to use correct spelling, punctuation, and capitalization. Graders also consider your handwriting if you take the handwritten version of the test.</p>
                :
                <p className='khmer'>លក្ខណៈវិនិច្ឆ័យនេះវាយតម្លៃសមត្ថភាពរបស់អ្នកក្នុងការប្រើប្រាស់អក្ខរាវិរុទ្ធ វណ្ណយុត្តិ និងអក្សរធំត្រឹមត្រូវ។ សិស្ស​ថ្នាក់​ក៏​ពិចារណា​លើ​ការ​សរសេរ​ដៃ​របស់​អ្នក​ដែរ ប្រសិន​បើ​អ្នក​ធ្វើ​តេស្ត​សរសេរ​ដោយ​ដៃ។</p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradingRubricModal;
