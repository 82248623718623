import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaPlay, FaHistory } from 'react-icons/fa';
import Loading from '../Loading';

const VideoHistory = () => {
  const [videos, setVideos] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    const fetchVideoHistory = async () => {
      try {
        const response = await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/history/videos', { headers });
        setVideos(response.data);
        setIsFetching(false);
      } catch (error) {
        console.error('Error fetching video history:', error);
        setIsFetching(false);
      }
    };

    fetchVideoHistory();
  }, []);

  if (isFetching) return <Loading />;

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="mt-10 mx-auto"
    >
      <div className="flex items-center mb-8">
        <FaHistory className="lg:text-xl text-lg text-blue-600 mr-4" />
        <h1 className="lg:text-xl text-lg font-bold text-gray-800">Video Course History</h1>
      </div>
      {videos.length === 0 ? (
        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="flex flex-col items-center justify-center bg-white rounded-lg shadow-md p-8"
        >
          <FaPlay className="text-5xl text-gray-300 mb-4" />
          <p className="text-center text-lg text-gray-500 khmer">មិនទាន់មានវីដេអូទេ</p>
          <button className="mt-6 px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 ease-in-out">
            Start Learning
          </button>
        </motion.div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {videos.map((video, index) => (
            <motion.a
              href='/video'
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-3 text-gray-800">{video.title}</h3>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-xs text-gray-600">
                    Episode: {video.CurrentEpisode} / {video.TotalEpisodes}
                  </span>
                  <span className="text-xs font-semibold text-blue-600">
                    {Math.floor(video.progress)}% Complete
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
                  <motion.div
                    className="bg-blue-600 h-2 rounded-full"
                    initial={{ width: 0 }}
                    animate={{ width: `${video.progress}%` }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  ></motion.div>
                </div>
                <button className="w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 ease-in-out flex items-center justify-center">
                  <FaPlay className="mr-2" /> Continue Learning
                </button>
              </div>
            </motion.a>
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default VideoHistory;