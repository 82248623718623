import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProfileImage from '../../components/Profile/ProfileImage';
import ProfileInfo from '../../components/Profile/ProfileInfo';
import ProfileButtons from '../../components/Profile/ProfileButtons';
import Loading from '../../components/Loading';
import ResetPasswordModal from '../../components/Profile/ResetPasswordModal';
import EditProfileModal from '../../components/Profile/EditProfileModal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PremiumParts from '../../components/Profile/PremiumParts';
import verifyUser from '../../functions/Verify';

const ProfileScreen = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isResetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [isEditProfileModalVisible, setEditProfileModalVisible] = useState(false); // For profile edit modal
  const [accessStatus, setAccessStatus] = useState(false)
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };
  const navigate = useNavigate();

  const fetchAccessStatus = async () => {
    const access = await verifyUser()
    setAccessStatus(access)
  }

  const fetchProfile = async () => {
    try {
      const response = await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/profile', { headers });
      setProfile(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccessStatus();
    fetchProfile(); // Fetch profile on component mount
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
    window.location.reload();
  };

  const handleResetPassword = async (email, newPassword) => {
    try {
      await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/user/forgot-password', {
        email,
        newPassword,
      });
      toast.success('Password has been reset successfully');
      setResetPasswordModalVisible(false); // Close the modal on success
    } catch (error) {
      console.error('Error resetting password:', error);
      toast.error('Failed to reset password, please try again later.');
    }
  };

  const handleProcessClick = () => {
    navigate('/learning')
  }

  const handleSubscriptionClick = () => {
      navigate('/account')
  }

  if (loading) return <Loading message="Loading profile..." />;

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-6 pb-24 pt-24">
      <div className="w-full max-w-md">
        <ProfileImage name={profile.Name} profilePicURL={profile.ProfilePicURL} />
        <PremiumParts
          onLearningProgressClick={handleProcessClick}
          onSubscriptionClick={handleSubscriptionClick}
          accessStatus={accessStatus}
        />
        <ProfileInfo profile={profile} />
        <ProfileButtons 
          onLogoutPress={handleLogout} 
          onResetPasswordPress={() => setResetPasswordModalVisible(true)}  // Show the reset password modal
          onEditProfilePress={() => setEditProfileModalVisible(true)}  // Show the edit profile modal
          onPrivacyPolicyPress={() => navigate('/privacy-policy')}
        />
      </div>

      {/* Reset Password Modal */}
      <ResetPasswordModal
        isVisible={isResetPasswordModalVisible}
        onClose={() => setResetPasswordModalVisible(false)}
        onSubmit={handleResetPassword}  // Handle form submission inside the modal
      />

        <EditProfileModal
            isVisible={isEditProfileModalVisible}
            onClose={() => setEditProfileModalVisible(false)}

        />


    </div>
  );
};

export default ProfileScreen;
