import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from '../../components/Modal';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import VocabHero from '../../components/VocabsHero';
import SearchBar from '../../components/Vocabs/SearchBar';
import FilterButtons from '../../components/Vocabs/FilterButtons';
import FlashcardSetList from '../../components/Vocabs/FlashcardSetList';

const AllVocabs = ({ accessStatus }) => {
  const [sets, setSets] = useState([]);
  const [filteredSets, setFilteredSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('All');
  const [showLoginModal, setShowLoginModal] = useState(false);

  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    const fetchSets = async () => {
      try {
        const response = token
          ? await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/vocabs/loggedIn', { headers })
          : await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/vocabs/sets');
        setSets(response.data);
        setFilteredSets(response.data);
      } catch (error) {
        console.error('Error fetching vocab sets:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSets();
  }, [token]);

  const navigate = useNavigate();

  const handleNav = (setId) => {
    if (!accessStatus) {
      setShowLoginModal(true);
    } else {
      navigate(`/vocab/${setId}/`);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    filterSets(query, selectedLevel);
  };

  const handleLevelFilter = (level) => {
    setSelectedLevel(level);
    filterSets(searchQuery, level);
  };

  const filterSets = (query, level) => {
    let filteredData = sets;

    // Apply level filters
    if (level === 'In Progress') {
      filteredData = filteredData.filter((set) => set.Score > 0);
    } else if (level === 'New') {
      filteredData = filteredData.filter((set) => !set.Score || set.Score === 0);
    } else if (level !== 'All') {
      filteredData = filteredData.filter((set) => set.LevelName === level);
    }

    // Apply search filter
    if (query) {
      filteredData = filteredData.filter((set) =>
        set.SetName.toLowerCase().includes(query.toLowerCase())
      );
    }

    setFilteredSets(filteredData);
  };

  return (
    <div className="flex flex-col h-full bg-gradient-to-r from-blue-100 to-indigo-100 pt-24 pb-20">
      <Outlet />
      <VocabHero />
      <div className="container mx-auto px-4 py-6">
        <SearchBar searchQuery={searchQuery} handleSearch={handleSearch} />
        <FilterButtons selectedLevel={selectedLevel} handleLevelFilter={handleLevelFilter} />
        <FlashcardSetList sets={filteredSets} handleSelectSet={handleNav} />
      </div>

      {showLoginModal && (
        <Modal onClose={() => setShowLoginModal(false)} title="Oops! Please login!">
          <div className="bg-white p-4 rounded-md">
            <p className="text-gray-600 mb-4">Please login to access this flashcard set.</p>
            <div className="flex justify-center">
              <button
                onClick={() => navigate('/login')}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
              >
                Login <FaLockOpen className="ml-2" />
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AllVocabs;
