import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaPlay, FaPause, FaForward, FaBackward, FaBookOpen, FaGraduationCap, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function ProgressOverview() {
  const [videoHistory, setVideoHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchVideoHistory();
  }, []);

  const fetchVideoHistory = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      setIsLoading(true);
      const response = await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/history/videos', { headers });
      setVideoHistory(response.data);
      
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching video history:', error);
      setError('Failed to load video history. Please try again later.');
      setIsLoading(false);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }


  const handlePlay = (id) => {
    navigate(`/video`);
  }

  return (
    <div className="mt-4 py-2">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">Your Video Progress</h2>

        {videoHistory.length === 0 ? (
          <div className="bg-white rounded-lg shadow-md p-6 text-center">
            <p className="text-gray-600 text-lg md:text-xl">You haven't started any courses yet. Begin your learning adventure!</p>
          </div>
        ) : (
          <div className="space-y-6">
            {videoHistory.map((course, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-2">{course.title}</h2>
                      <p className="text-gray-600 text-sm md:text-base">Episode {course.CurrentEpisode} of {course.TotalEpisodes}</p>
                    </div>
                    <div className="bg-blue-100 text-blue-800 text-xs md:text-sm font-semibold px-2.5 py-0.5 rounded-full">
                      {(parseFloat(course.progress))}% Complete
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex justify-between text-sm md:text-base text-gray-600 mb-1">
                      <span>Progress</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${parseFloat(course.progress)}%` }}></div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center space-x-4">
                    <button onClick={() => handlePlay(course.CurrentEpisode)} className="bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700 transition-colors">
                      {parseFloat(course.progress) < 1 ? <FaPlay size={20} /> : <FaPause size={20} />}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="mt-12 bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center text-gray-800">Learning Stats</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
            <div className="bg-blue-50 p-6 rounded-lg text-center">
              <FaBookOpen className="text-4xl text-blue-600 mx-auto mb-4" />
              <p className="text-sm md:text-base text-gray-600 mb-2">Courses Started</p>
              <p className="text-3xl md:text-4xl font-bold text-blue-600">{videoHistory.length}</p>
            </div>
            <div className="bg-green-50 p-6 rounded-lg text-center">
              <FaGraduationCap className="text-4xl text-green-600 mx-auto mb-4" />
              <p className="text-sm md:text-base text-gray-600 mb-2">Total Episodes</p>
              <p className="text-3xl md:text-4xl font-bold text-green-600">
                {videoHistory.reduce((total, course) => total + course.TotalEpisodes, 0)}
              </p>
            </div>
            <div className="bg-purple-50 p-6 rounded-lg text-center">
              <FaChartLine className="text-4xl text-purple-600 mx-auto mb-4" />
              <p className="text-sm md:text-base text-gray-600 mb-2">Average Progress</p>
              <p className="text-3xl md:text-4xl font-bold text-purple-600">
                {videoHistory.length > 0
                  ? `${Math.round(
                      videoHistory.reduce((sum, course) => sum + parseFloat(course.progress), 0) /
                        videoHistory.length *
                        100
                    )}%`
                  : '0%'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}