import React, { useState } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';
import { toast } from 'react-toastify';

const Modal = ({ title, children, onClose }) => {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 overflow-y-auto h-full w-full z-50 flex justify-center items-center">
        <div className="relative bg-white rounded-lg shadow-xl p-5 md:p-8 mx-4 my-8 max-w-2xl w-full">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-800 whitespace-nowrap">{title}</h2>
            <button onClick={onClose} className='rounded-full p-1 hover:bg-gray-200 transition-colors duration-150'>
              <RxCross2 size={24} color='black' className='cursor-pointer'/>
            </button>
          </div>
          <div className="mt-4">
            {children}
          </div>
        </div>
      </div>
    );
  };

export default function Feedback() {
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [feedback, setFeedback] = useState('');

    const toggleFeedbackModal = () => setIsFeedbackModalOpen(!isFeedbackModalOpen);
    const [error, setError] = useState(null);

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/feedback/', { feedback }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('អរគុណសម្រាប់មតិរបស់អ្នក!');
      toggleFeedbackModal(); // Close modal after submission
      setFeedback(''); // Reset feedback input
    } catch (error) {
      toast.error('អ្នកបានបញ្ចេញមតិហើយ! មួយថ្ងៃបានម្តង!');
      setFeedback(''); // Reset feedback input
    }
  };

  return (
    <>
      {/* Feedback Section */}
      <>
        <button
          onClick={toggleFeedbackModal}
          className="khmer text-lg bg-gradient-to-r from-cyan-400 to-blue-400 font-bold text-shadow transform transition hover:scale-105 text-white py-2 px-6 rounded hover:bg-gradient-to-r hover:from-blue-400 hover:to-cyan-400 transition duration-300 animate-pulse"
        >
          បញ្ចេញមតិ
        </button>
      </>

      {/* Feedback Modal */}
      {isFeedbackModalOpen && (
        <Modal onClose={toggleFeedbackModal} title={'Feedback'}>
          <form onSubmit={handleFeedbackSubmit}>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              className="w-full p-2 border rounded text-black"
              placeholder="Type your feedback here..."
              required
            ></textarea>
            <div className="text-right mt-4">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Submit Feedback
              </button>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
}
