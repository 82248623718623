import React from 'react';
import { FaRocket, FaClock, FaCheckCircle } from 'react-icons/fa';

export default function FreeTrial({ cta, handleCta, accessStatus }) {
  return (
    <section className="bg-gradient-to-r from-blue-100 to-cyan-100 text-gray-800 rounded-2xl  p-8 mb-12 relative overflow-hidden">
      <div className="absolute top-0 right-0 w-64 h-64 bg-blue-200 opacity-30 rounded-full transform translate-x-1/3 -translate-y-1/3"></div>
      <div className="relative z-10 max-w-4xl mx-auto text-center">
        <h2 className="text-3xl sm:text-4xl font-extrabold mb-6 khmer leading-tight text-blue-800">
          ចាប់ផ្តើមរៀនអង់គ្លេសដោយឥតគិតថ្លៃ<br/>
          <span className="text-cyan-600">រយៈពេល 7 ថ្ងៃ!</span>
        </h2>
        <div className="flex flex-col sm:flex-row justify-center items-center mb-8 space-y-4 sm:space-y-0 sm:space-x-8">
          <div className="flex items-center bg-white bg-opacity-50 p-3 rounded-lg">
            <FaRocket className="text-cyan-600 text-2xl mr-2" />
            <p className="khmer text-lg text-gray-800">រៀនពី 0 ដល់កម្រិតខ្ពស់</p>
          </div>
          <div className="flex items-center bg-white bg-opacity-50 p-3 rounded-lg">
            <FaClock className="text-cyan-600 text-2xl mr-2" />
            <p className="khmer text-lg text-gray-800">រៀនគ្រប់ពេលវេលា គ្រប់ទីកន្លែង</p>
          </div>
          <div className="flex items-center bg-white bg-opacity-50 p-3 rounded-lg">
            <FaCheckCircle className="text-cyan-600 text-2xl mr-2" />
            <p className="khmer text-lg text-gray-800">គ្មានកាតព្វកិច្ច អាចបោះបង់បាន</p>
          </div>
        </div>
        <div className="mb-8">
          <button 
            onClick={handleCta}
            className="khmer bg-gradient-to-r from-blue-500 to-cyan-500 text-white font-bold py-3 px-8 sm:py-4 sm:px-10 rounded-full text-lg sm:text-xl hover:from-blue-600 hover:to-cyan-600 transition duration-300 transform hover:scale-105 shadow-lg"
          >
            {cta()}
          </button>
        </div>
        {accessStatus !== 'granted' && (
          <div className="bg-white bg-opacity-70 p-4 rounded-lg">
            <p className="khmer text-lg mb-2 text-gray-800">ចាប់ផ្តើមឥឡូវនេះ មុនពេលផុតកំណត់!</p>
            <p className="khmer text-xl font-semibold text-gray-800">
              រយៈពេលសាកល្បងឥតគិតថ្លៃនឹងបញ្ចប់នៅ{' '}
              <span className="text-cyan-600">ថ្ងៃទី 30/09/2024</span>
            </p>
          </div>
        )}
      </div>
    </section>
  );
}