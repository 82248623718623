import React from 'react';
import { FaLock, FaSignOutAlt, FaShieldAlt } from 'react-icons/fa';

const ProfileButtons = ({ onLogoutPress, onResetPasswordPress, onPrivacyPolicyPress }) => {
  return (
    <div className="mt-6 w-full max-w-md bg-white p-4 rounded-lg overflow-hidden border border-gray-200">
      <ButtonComponent
        label="ផ្តូរលេខសម្ងាត់"
        icon={<FaLock className="mr-4 text-xl" />}
        onClick={onResetPasswordPress}
        color="text-yellow-500"
        borderTop
        extra="pt-0 khmer"
      />
      <ButtonComponent
        label="Privacy Policy"
        icon={<FaShieldAlt className="mr-4 text-xl" />}
        onClick={onPrivacyPolicyPress}
        color="text-green-500"
        extraClasses="border-t border-b border-gray-200"
      />
      <ButtonComponent
        label="ចាកចេញ"
        icon={<FaSignOutAlt className="mr-4 text-xl" />}
        onClick={onLogoutPress}
        color="text-red-500"
        borderBottom
        extra="pb-0 khmer"
      />
    </div>
  );
};

const ButtonComponent = ({ label, icon, onClick, extraClasses = '', color, extra }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center w-full py-2  ${color} ${extraClasses} hover:bg-gray-100 transition duration-300 ${extra}`}
    >
      {icon}
      <span className="text-lg md:text-xl font-semibold">{label}</span>
    </button>
  );
};

export default ProfileButtons;
