import React, { useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGlobe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import EditProfileModal from './EditProfileModal';

const ProfileImage = ({ profilePicURL, name }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state

  const handleEditProfile = () => {
    setIsModalVisible(true); // Open modal
  };

  const handleCloseModal = () => {
    setIsModalVisible(false); // Close modal
  };

  return (
    <div className="flex flex-col items-center w-full rounded-lg ">
      {/* Profile Image */}
      <div className="relative w-40 h-40">
        <img
          src={profilePicURL || '/default-avatar.png'}
          alt="Profile"
          className="w-full h-full rounded-full border-4 border-blue-500 object-cover"
        />
      </div>

      {/* Profile Name */}
      <h2 className="mt-4 text-2xl font-bold">{name || 'Anonymous'}</h2>

      {/* Buttons and Links */}
      <div className="w-full mt-4 border-gray-200 rounded-lg border p-4 bg-white">
        {/* Edit Profile Button */}
        <button 
          className="flex items-center justify-start py-2 hover:bg-gray-50 w-full pt-0 border-b" 
          onClick={handleEditProfile} // Trigger the modal
        >
          <BiEdit className="mr-2 text-yellow-600" />
          <span className="text-yellow-600 text-lg md:text-xl font-semibold khmer">កែរូប និងឈ្មោះ</span>
        </button>

        {/* External Link */}
        <a
          href="https://www.botumofficial.com"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-start hover:bg-gray-50 py-2 pb-0"
        >
          <FaGlobe className="mr-2 text-green-600" />
          <span className="text-green-600 text-lg md:text-xl font-semibold">www.botumofficial.com</span>
        </a>
      </div>

      {/* Modal for Editing Profile */}
      {isModalVisible && (
        <EditProfileModal
          isVisible={isModalVisible}
          onClose={handleCloseModal}
          name={name}
          profilePicURL={profilePicURL}
        />
      )}
    </div>
  );
};

export default ProfileImage;
