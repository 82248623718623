import React from 'react';
import { useNavigate } from 'react-router-dom';
import CurrentlyLearningCard from './CurrentlyLearningCard.js'

const LearningHistory = ({ documents, filterValue, setFilterValue, handleDocumentPress, getBackgroundColor }) => {
  const filteredDocuments = documents.filter(doc => {
    if (filterValue === 'all') return true;
    if (filterValue === 'completed') return doc.Completed === 1;
    if (filterValue === 'notCompleted') return doc.Completed === 0;
    return true;
  });
  


  return (
    <div className="mt-10">
      <h1 className="text-xl font-semibold mb-4">Document Learning History</h1>
      <div className="mb-4 flex justify-center">
        <label className="inline-flex items-center cursor-pointer mr-4 p-2 border rounded-lg border-gray-300 hover:bg-gray-100 transition duration-300">
          <input
            type="radio"
            value="all"
            checked={filterValue === 'all'}
            onChange={() => setFilterValue('all')}
            className="form-radio h-5 w-5 text-blue-600"
          />
          <span className="ml-2 text-sm text-gray-800">All</span>
        </label>
        <label className="inline-flex items-center cursor-pointer mr-4 p-2 border rounded-lg border-gray-300 hover:bg-gray-100 transition duration-300">
          <input
            type="radio"
            value="completed"
            checked={filterValue === 'completed'}
            onChange={() => setFilterValue('completed')}
            className="form-radio h-5 w-5 text-blue-600"
          />
          <span className="ml-2 text-sm text-gray-800 khmer">បញ្ចប់</span>
        </label>
        <label className="inline-flex items-center cursor-pointer p-2 border rounded-lg border-gray-300 hover:bg-gray-100 transition duration-300">
          <input
            type="radio"
            value="notCompleted"
            checked={filterValue === 'notCompleted'}
            onChange={() => setFilterValue('notCompleted')}
            className="form-radio h-5 w-5 text-blue-600"
          />
          <span className="ml-2 text-sm text-gray-800 khmer">កំពុងរៀន</span>
        </label>
      </div>

      {filteredDocuments.length === 0 ? (
        <div className='flex justify-center items-center'>
          <p className="text-center text-gray-500 khmer">មិនទាន់មានឯកសារទេ</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6">
          {filteredDocuments.map((doc, index) => (
            <CurrentlyLearningCard
              doc={doc}
              index={index}
              accessStatus={true}
              handleDocumentPress={handleDocumentPress}
              getBackgroundColor={getBackgroundColor}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LearningHistory;
