function getBackgroundColor(levelName) {
    switch (levelName) {
        case "Beginner":
            return "bg-gradient-to-r from-sky-100 to-blue-200"; // Add the CSS class for Beginner background color
        case "Intermediate":
            return "bg-gradient-to-r from-orange-100 to-cyan-100"; // Add the CSS class for Intermediate background color
        case "Advanced":
            return "bg-gradient-to-r from-purple-100 to-rose-100"; // Add the CSS class for Advanced background color
        default:
            return "bg-gray-100"; // Default background color
    }
}

export default getBackgroundColor;