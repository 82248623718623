import React, { useEffect, useState } from 'react';
import { FaBookOpen, FaPenAlt, FaChalkboardTeacher, FaFireAlt, FaClock, FaHeart } from 'react-icons/fa';
import FeaturesSection from '../../components/HomePage/FeaturesSection';
import LoggedInSection from '../../components/HomePage/LoggedInSection';
import ProgressOverview from '../../components/HomePage/ProgressOverview';
import { Link } from 'react-router-dom';
import NoPremiumDisplay from '../../components/HomePage/NoPremiumDisplay';
import verifyUser from '../../functions/Verify';
import LoggedInHeroSection from './LoggedInHeroSection';
import axios from 'axios';
import DownloadAppSection from '../../components/HomePage/DownloadAppSection';

export default function LoggedInPage({ }) {
  const [accessStatus, setAccessStatus] = useState(null);
  const [name, setName] = useState(null);

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };


  const fetchProfile = async () => {
    try {
      const response = await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/profile', { headers });
      setName(response.data.Name);
    } catch (error) {
      console.error('Error fetching profile:', error);
    } 
  };

  useEffect(() => {
    fetchProfile()

    const checkUserStatus = async () => {
        const status = await verifyUser();
          setAccessStatus(status);
      };

      checkUserStatus();
  }, []);

  return (
    <div className="bg-gray-50 py-12">
      <LoggedInHeroSection name={name}/>

      {/* Progress Overview */}
      <section className="container mx-auto px-4 py-12 pb-0">

        {accessStatus === 'granted' ? (
          <ProgressOverview />
        ) : (
          <NoPremiumDisplay />
        )}
      </section>

      <DownloadAppSection/>

      {/* Features Section */}
      <section className="container mx-auto px-4 py-12">
        <LoggedInSection/>
      </section>
    </div>
  );
}