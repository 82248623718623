import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import stripePromise from './StripePromise';
import axios from 'axios';
import { toast } from 'react-toastify';

const Form = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false); // Track if payment is being submitted

      
  const token = localStorage.getItem('token'); // Get the token from local storage

  const headers = {
      Authorization: `Bearer ${token}`
  };
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsSubmitting(true); // Start submitting payment
  
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable form submission or show a loading indicator.
        return;
      }
  
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
  
      if (error) {
        console.error(error);
        toast.error('Payment failed. Please check your card details.');
      } else {
        // Payment succeeded, you can send the paymentMethod.id to your server for further processing
        console.log('PaymentMethod:', paymentMethod);
  
        const response = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/subscription/update-subscription-payment-method', { paymentMethod: paymentMethod }, { headers });
  
        if (response.status === 200) {
          toast.success('Payment method updated successfully.');
          // Wait for a moment before reloading
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error('Failed to update payment method. Please try again.');
        }
      }
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setIsSubmitting(false); // Ensure isSubmitting is set to false regardless of success or error
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto">
    <div className="bg-white px-8 mb-4">
        <div className="bg-gray-100 rounded-lg p-2 mb-4">
        <CardElement
            options={{
            style: {
                base: {
                fontSize: '16px',
                '::placeholder': {
                    color: '#A0AEC0', // Customize placeholder color
                },
                },
            },
            }}
        />
        </div>
        <div className='flex justify-center items-center'>
            <button
            className={`w-1/2 bg-green-500 text-white font-semibold py-2 rounded-2xl hover:bg-green-600 transition duration-300 ${isSubmitting ? 'opacity-70 cursor-wait' : ''}`}
            disabled={isSubmitting}
            >
            {isSubmitting ? 'Updating...' : 'Update'}
            </button>
        </div>
    </div>
    </form>


  );
};

const PaymentForm = () => {
    
    return (
        <Elements stripe={stripePromise}>
            <Form />
        </Elements>
    );
}



export default PaymentForm;
