import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BiLoaderAlt } from 'react-icons/bi'; // Importing a loading spinner icon
import { useNavigate, useParams } from 'react-router-dom';
import { CiRead } from "react-icons/ci";
import GradingRubricModal from '../../components/GradingRubricModal';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import formatContent from '../../functions/formattedContent';
import { FaStar, FaRegStar } from 'react-icons/fa'; // Import React icons
import AccessDenied from '../../components/AccessDenied';

const WritingPage = ({accessStatus}) => {
  const [userResponse, setUserResponse] = useState('');
  const [gradeInfo, setGradeInfo] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState({ title: '', prompt: '' });
  const [timer, setTimer] = useState(15 * 60); // 30 minutes in seconds
  const [timerStarted, setTimerStarted] = useState(false);
  const [isRubricModalOpen, setIsRubricModalOpen] = useState(false); // State to manage the rubric modal
  const [fetchLoading, isFetchLoading] = useState(false);

  const { promptId } = useParams();

  const navigate = useNavigate()

  // Function to render star icons based on score
const renderStars = (score) => {
  const filledStars = Math.floor(score / 10); // Number of filled stars
  const remainder = score % 10; // Remaining score

  const stars = [];
  // Render filled stars
  for (let i = 0; i < filledStars; i++) {
      stars.push(<FaStar key={i} />);
  }
  // Render half star if remainder >= 5
  if (remainder >= 5) {
      stars.push(<FaStar half key={filledStars} />);
  }
  // Render empty stars to complete 3 stars
  const emptyStars = 3 - stars.length;
  for (let i = 0; i < emptyStars; i++) {
      stars.push(<FaRegStar key={filledStars + i} />);
  }

  return stars;
};

  const fetchPrompt = async () => {
    isFetchLoading(true);
    axios.get(`https://tonletalk-e5cea0c5c5df.herokuapp.com/write/${promptId}`, { headers })
      .then(response => {
        setPrompt(response.data[0]);
        setTimer(response.data[0].allowedTime * 60)
      })
      .then(()=> {

      })
      .catch(error => console.error('Error fetching document:', error));
    isFetchLoading(false);
  }

  useEffect(() => {
    fetchPrompt();
  }, []);

  useEffect(() => {
    let timerInterval;
  
    if (timerStarted) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timerInterval); // Clear interval when timer reaches zero
            handleSubmit(); // Submit when timer reaches zero
            return 0; // Set timer to zero
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else {
      clearInterval(timerInterval); // Also clear interval when timer is not started
    }
  
    return () => clearInterval(timerInterval); // Clear interval on component unmount
  }, [timerStarted]);
  

  useEffect(() => {
    if (timer === 0) {
      handleSubmit();
    }
  }, [timer]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const formattedContent = formatContent(prompt.lectureReading || '');

  const handleStart = () => {
    setTimerStarted(true);
  };

  const token = localStorage.getItem('token'); // Get the token from local storage

  const headers = {
    Authorization: `Bearer ${token}`
    };
    function renderEssayEvaluation(evaluationText) {
        const criteria = evaluationText.split('\n');
      
        return (
          <div>
            {criteria.map((criterion, index) => (
              <div key={index}>
                {criterion.includes(':') ? (
                  <p className='text-lg'>
                    <strong>{criterion.split(':')[0]}</strong>: {criterion.split(':')[1]}
                  </p>
                ) : (
                  <>
                    <p>{criterion}</p>
                    <br />
                  </>
                )}
              </div>
            ))}
          </div>
        );
      }
      
      
      const takeToSubmit = () => {
        const submitButton = document.getElementById('grade-info');
    
        // Scroll to the submit button with smooth behavior
        if (submitButton) {
            submitButton.scrollIntoView({ behavior: 'smooth' });
        }
      }
      

      const handleSubmit = async () => {
          setLoading(true);
          setTimerStarted(false); // Pause the timer
        
          try {
            const result = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/write/', {
              lectureReading: prompt.lectureReading, 
              prompt: prompt.prompt, 
              userResponse: userResponse,
              promptId: promptId
            }, { headers });
            setGradeInfo(result.data); // Assuming the API returns the structured grade information
          } catch (error) {
            console.error('Error generating grade:', error);
          } finally {
            setLoading(false);
            takeToSubmit();
            fetchPrompt()
          }
        };
      
  if(fetchLoading) {
    return <Loading/>
  }

  if(!prompt.free && !accessStatus === 'granted') {
    return <AccessDenied/>
  }

  return (
    <div className="container mx-auto p-4 pt-24 lg:pt-28 md:pt-28 ">
    <div className="flex flex-col sm:flex-row md:flex-row items-center justify-between mb-4">
        <h1 className="lg:text-3xl sm:text-3xl text-3xl font-semibold mb-2 sm:mb-0 lg:text-left md:text-left text-center">{prompt.title}</h1> {/* Responsive Page title */}

        <div className='flex items-center'>
            <span className="text-lg font-semibold text-gray-500">Time Remaining: <span className='text-black'>{formatTime()}</span></span>
            {!timerStarted && (
                <button
                    className="ml-2 sm:ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={handleStart}
                    disabled={isLoading || gradeInfo !== null} // Disable button if loading or graded
                >
                    Start
                </button>
            )}
        </div>
        
    </div>
    {prompt.Score != 0 && 
      <div className="flex items-center justify-center md:justify-start lg:justify-start xl:justify-start">
        {/* Render stars for the score */}
          <div className="flex items-center">
              <span className="">{prompt.Score}/30</span>
          </div>

          {/* Render grading based on score */}
          {prompt.Score >= 25 && <span className="text-green-500 ml-2 text-3xl font-bold">A</span>}
          {prompt.Score >= 20 && prompt.Score < 25 && <span className="text-blue-500 text-3xl ml-2">B</span>}
          {prompt.Score >= 15 && prompt.Score < 20 && <span className="text-yellow-500 text-3xl ml-2">C</span>}
          {prompt.Score < 15 && <span className="text-red-500 text-3xl ml-2">D</span>}
        </div>
      }


        <div className='bg-gray-100 p-4 rounded-lg my-4 mx-2'>
                  
            <p>{formattedContent}</p>
        </div>

      <div className="flex flex-col space-y-4">
        {/* Writing Prompt */}
        <p className="font-semibold text-md">Prompt:<span className='font-normal'> {prompt.prompt}</span></p>
        <div>
          <button className="font-semibold text-md ml-0 flex items-center khmer hover:opacity-50 transition" onClick={() => setIsRubricModalOpen(true)}>
            របៀបដាក់ពិន្ទុ <CiRead className='ml-1' size={24}/>
          </button>
        </div>


        {/* User Response */}
        <div className="flex flex-col h-96">
          <label htmlFor="user-response" className="font-semibold mb-2">Your Response:</label>
          <textarea
            id="user-response"
            className="border border-gray-300 p-2 rounded shadow-sm h-full resize-none"
            value={userResponse}
            onChange={(e) => setUserResponse(e.target.value)}
          />
        </div>

        {/* Submit Button */}
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold transition py-2 px-4 rounded disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={handleSubmit}
          disabled={isLoading || gradeInfo !== null} // Disable button if loading or graded
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <BiLoaderAlt className="animate-spin mr-2" /> {/* Loading animation */}
              Loading...
            </div>
          ) : (
            "Submit for Grading"
          )}
        </button>

      </div>

      {/* Display the Grade Information */}
      {gradeInfo && (
        <div className="mt-4 bg-white shadow-lg rounded-lg p-4" id='grade-info'>
            <h3 className="text-xl font-semibold">Grade Report</h3>
                <p>
                    {renderEssayEvaluation(gradeInfo)}
                </p>
        </div>
        )}

      { isRubricModalOpen && (
        <GradingRubricModal onClose={() => setIsRubricModalOpen(false)} />
      )}

      {gradeInfo && (
        <div className='w-full mt-8 '>
          <button className='w-full border rounded p-1 transition hover:opacity-80 bg-orange-400 text-white font-bold text-lg' onClick={() => {navigate(-1)}}>Go Back</button>
        </div>
      )}

    </div>
  );
};

export default WritingPage;
