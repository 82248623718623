import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

const PrivacyPolicyScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white py-6 px-4 sm:px-6 lg:px-8 pt-20 pb-20"> {/* Add padding for header and footer */}
      <div className="max-w-3xl mx-auto">
        {/* Header Section */}
        <div className="flex items-center mb-8">
          <button onClick={() => navigate(-1)} className="text-black mr-4">
            <FaArrowLeft className="text-xl" /> {/* Using React Icon */}
          </button>
          <h1 className="text-3xl font-bold text-gray-800">Privacy Policy</h1>
        </div>

        {/* Content Section */}
        <div className="text-gray-600 space-y-6">
          <p className="text-lg">Effective Date: August 28, 2024</p>
          <p>
            Welcome to TonleTalk. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the application.
          </p>

          <h2 className="text-2xl font-bold text-gray-800">1. Information We Collect</h2>
          <p>
            We may collect information about you in a variety of ways. The information we may collect via the application includes:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>Personal Data</strong>: Demographic and other personally identifiable information (such as your name and email address) that you voluntarily give to us when choosing to participate in various activities related to the application.
            </li>
            <li>
              <strong>Derivative Data</strong>: Information our servers automatically collect when you access the application, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the application.
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-800">2. Use of Your Information</h2>
          <p>
            Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the application to:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Create and manage your account.</li>
            <li>Email you regarding your account or order.</li>
            <li>Enable user-to-user communications.</li>
            <li>Generate a personal profile about you to make future visits to the application more personalized.</li>
            <li>Increase the efficiency and operation of the application.</li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-800">3. Disclosure of Your Information</h2>
          <p>
            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              <strong>By Law or to Protect Rights</strong>: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
            </li>
          </ul>

          <h2 className="text-2xl font-bold text-gray-800">4. Security of Your Information</h2>
          <p>
            We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no security measures are perfect or impenetrable.
          </p>

          <h2 className="text-2xl font-bold text-gray-800">5. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by posting the new Privacy Policy on this page.
          </p>

          <h2 className="text-2xl font-bold text-gray-800">6. Contact Us</h2>
          <p>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at: <a href="mailto:tonletalk@gmail.com" className="text-blue-500 underline">tonletalk@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyScreen;
