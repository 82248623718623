import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SaveScreenDimensions = () => {
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const token = localStorage.getItem('token');
  const deviceId = navigator.userAgent; // Use userAgent as a simple device identifier

  // Capture screen dimensions when the component mounts or window resizes
  useEffect(() => {
    const handleResize = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Save dimensions to the backend on component mount
    if (token) {
      saveScreenDimensions(screenDimensions);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [screenDimensions]);

  // Function to send screen dimensions to the backend
  const saveScreenDimensions = async (dimensions) => {
    try {
      await axios.post(
        'https://tonletalk-e5cea0c5c5df.herokuapp.com/user/save-screen-dimensions',
        {
          width: dimensions.width,
          height: dimensions.height,
          deviceId: deviceId, // Send deviceId along with dimensions
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Screen dimensions saved successfully');
    } catch (error) {
      console.error('Error saving screen dimensions:', error);
    }
  };

  return null; // Since we don't need any UI, the component returns nothing
};

export default SaveScreenDimensions;
