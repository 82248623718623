import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaTelegram, FaStar, FaChalkboardTeacher, FaBook } from 'react-icons/fa';
import { TbCardsFilled } from 'react-icons/tb';
import { Link } from 'react-router-dom';

export default function Hero({ scrollToSection }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const waveVariants = {
    animate: {
      y: [0, -10, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "easeInOut",
      },
    },
  };

  return (
    <section className="bg-gray-100 text-gray-800 py-20 px-4 sm:px-6 lg:px-8 min-h-screen flex items-center relative overflow-hidden">
      {/* Wave animations */}
      <div className="absolute inset-0 z-0">
        <motion.div
          className="absolute bottom-0 left-0 right-0 h-20 bg-blue-500 opacity-10"
          variants={waveVariants}
          animate="animate"
          style={{ borderRadius: "50% 50% 0 0" }}
        />
        <motion.div
          className="absolute bottom-0 left-0 right-0 h-16 bg-blue-400 opacity-10"
          variants={waveVariants}
          animate="animate"
          style={{ borderRadius: "50% 50% 0 0" }}
          transition={{ delay: 0.2 }}
        />
        <motion.div
          className="absolute bottom-0 left-0 right-0 h-12 bg-blue-300 opacity-10"
          variants={waveVariants}
          animate="animate"
          style={{ borderRadius: "50% 50% 0 0" }}
          transition={{ delay: 0.4 }}
        />
      </div>

      <div className="max-w-7xl mx-auto w-full relative z-10">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8 items-center">
          <div className="lg:col-span-7">
            <motion.h1 
              className="text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl khmer mb-6 text-gray-900"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              ចេះភាសា<span className="text-blue-600">អង់គ្លេស</span><br />
              ជាមួយ Tonle Talk
            </motion.h1>
            <motion.p 
              className="mt-3 text-base sm:mt-5 sm:text-xl lg:text-lg xl:text-xl khmer mb-8 text-gray-600"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Tonle Talk គឺជាកម្មវិធីដែលប្រើបច្ចេកវិទ្យាទំនើបបំផុតដើម្បីជួយអ្នករៀនភាសាអង់គ្លេសពី 0 ដល់កម្រិតខ្ពស់
            </motion.p>
            <div className="mt-8 flex flex-col sm:flex-row sm:justify-start">
              <motion.button
                onClick={toggleModal}
                className="w-full sm:w-auto px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10 khmer shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                រៀនរហូតត្រឹម $29.99
              </motion.button>
              <motion.button
                onClick={() => scrollToSection()}
                className="mt-3 sm:mt-0 sm:ml-3 w-full sm:w-auto px-8 py-3 border border-blue-600 text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50 md:py-4 md:text-lg md:px-10 khmer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                មើលបន្ថែម
              </motion.button>
            </div>
            <div className="mt-8 flex items-center space-x-6">
              <div className="flex items-center">
                <FaStar className="text-yellow-400 mr-2" />
                <span className="font-bold text-2xl">4.8</span>
              </div>
              <div className="khmer text-lg text-gray-600">
                អ្នករៀន 4000+ នាក់
              </div>
            </div>
            <motion.div
              className="mt-6 text-left sm:text-left"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Link to="/login" className="text-blue-600 hover:text-blue-800 transition duration-300 khmer">
                មានគណនីរួចហើយ? ចូលប្រើប្រាស់
              </Link>
            </motion.div>
          </div>
          <div className="mt-12 relative lg:mt-0 lg:col-span-5">
            <motion.div
              className="bg-white rounded-lg shadow-xl p-6"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <h3 className="text-2xl font-bold mb-4 khmer text-gray-800">មេរៀនថ្មីៗ</h3>
              <ul className="space-y-4">
                {[
                  { icon: FaChalkboardTeacher, title: "វីឌេអូមេរៀន", duration: "110+ មេរៀន" },
                  { icon: FaBook, title: "ឯកសារ", duration: "60+ ឯកសារ" },
                  { icon: TbCardsFilled, title: "រៀនពាក្យសព្ទ", duration: "20+ មេរៀន" },
                ].map((lesson, index) => (
                  <motion.li 
                    key={index}
                    className="flex items-center bg-gray-50 rounded-md p-4"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.8 + index * 0.2 }}
                  >
                    <lesson.icon className="text-blue-500 mr-4 text-2xl" />
                    <div>
                      <p className="font-semibold khmer text-gray-800 text-lg">{lesson.title}</p>
                      <p className="text-base text-gray-500 khmer">{lesson.duration}</p>
                    </div>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm flex justify-center items-center p-4 z-50">
          <motion.div 
            className="bg-white rounded-lg p-8 max-w-md w-full shadow-2xl"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
          >
            <h2 className="text-3xl font-bold mb-4 text-gray-900 khmer">ការផ្តល់ជូនពិសេស!</h2>
            <p className="mb-6 text-gray-600 khmer">បង់ម្តង រៀនបានរហូត! ទាំងមេរៀនចាស់ និងមេរៀនពេលដាក់បន្ថែមថ្ងៃក្រោយដូចជា Grammar, listening, speaking, និងច្រើនទៀត។ ផុតកំណត់ថ្ងៃ​ 30/09!</p>
            <a 
              href="https://t.me/tonletalklearning" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-md transition duration-300 text-center khmer"
            >
              <FaTelegram className="inline-block mr-2" />
              ទាក់ទង Telegram
            </a>
            <button 
              onClick={toggleModal} 
              className="mt-4 w-full border border-gray-300 text-gray-700 font-bold py-3 px-4 rounded-md hover:bg-gray-100 transition duration-300 khmer"
            >
              បិទ
            </button>
          </motion.div>
        </div>
      )}
    </section>
  );
}