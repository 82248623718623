import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DocumentPreview from '../../components/DocumentPreview';
import { Outlet } from 'react-router-dom';
import Modal from '../../components/Modal';
import { FaLockOpen } from 'react-icons/fa6';
import getBackgroundColor from '../../functions/randomColor';
import ReadingHero from '../../components/ReadingHero';
import Loading from '../../components/Loading';

export default function AllReadingPage({ accessStatus }) {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [freeFilter, setFreeFilter] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling/get-filters').then((response) => {
      setCategories(response.data.categories);
      setLevels(response.data.levels);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  const filteredDocuments = documents.filter(doc => {
    return (
      (doc.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.CategoryName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.LevelName.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (categoryFilter === '' || doc.CategoryName === categoryFilter) &&
      (levelFilter === '' || doc.LevelName === levelFilter) &&
      (freeFilter ? doc.Free : true)
    );
  }).sort((a, b) => {
    if (a.Free && !b.Free) return -1;
    if (!a.Free && b.Free) return 1;
    return 0;
  });

  const goToSubscribe = () => {
    navigate('/account');
  };

  const handleDocumentPress = (documentId, free) => {
    if (!token) {
      setShowLoginModal(true);
    } else if (free) {
      navigate(`/document/${documentId}`);
    } else if (!free && accessStatus === 'granted') {
      navigate(`/document/${documentId}`);
    } else {
      setShowModal(true);
    }
  };

  const handleFreeFilterChange = (e) => {
    setFreeFilter(e.target.checked);
  };

  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const FilterSection = (
    <div className="flex flex-wrap gap-4 w-full justify-center">
      <select
        className="border border-gray-300 p-2 rounded-lg shadow-sm w-full sm:w-1/3 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onChange={(e) => setCategoryFilter(e.target.value)}
        value={categoryFilter}
      >
        <option value="">All Categories</option>
        {categories.map((category, index) => (
          <option key={index} value={category.CategoryName}>
            {category.CategoryName}
          </option>
        ))}
      </select>

      <select
        className="border border-gray-300 p-2 rounded-lg shadow-sm w-full sm:w-1/3 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onChange={(e) => setLevelFilter(e.target.value)}
        value={levelFilter}
      >
        <option value="">All Levels</option>
        {levels.map((level, index) => (
          <option key={index} value={level.LevelName}>
            {level.LevelName}
          </option>
        ))}
      </select>

      {!accessStatus && (
        <select
          id="freeFilter"
          onChange={(e) => setFreeFilter(e.target.value === 'free')}
          value={freeFilter ? 'free' : 'all'}
          className="border border-gray-300 p-2 rounded-lg shadow-sm w-full sm:w-1/3 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">All Documents</option>
          <option value="free">Free</option>
        </select>
      )}
    </div>
  );

  useEffect(() => {
    setLoading(true);
    try {
      if (!token) {
        axios
          .get('https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling')
          .then((response) => {
            setDocuments(response.data);
          })
          .then(() => {
            setLoading(false);
          })
          .catch((error) => console.error('Error fetching documents:', error));
      } else {
        axios
          .get('https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling/allDocs', { headers })
          .then((response) => {
            setDocuments(response.data);
          })
          .then(() => {
            setLoading(false);
          })
          .catch((error) => console.error('Error fetching documents:', error));
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return documents.length !== 0 ? (
    <div className="pb-12 flex flex-col h-full lg:pt-12 md:pt-12 pt-16 sm:pt-16 bg-gradient-to-r from-white to-gray-100">
      <Outlet />
      <ReadingHero />

      <div className="px-4 bg-white py-6">
        <div className="py-4 flex flex-col sm:flex-row gap-4">
          <input
            type="text"
            placeholder="Search..."
            className="border border-gray-300 p-2 rounded-lg shadow-sm w-full sm:w-2/3 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <button
            onClick={() => setShowFilters(!showFilters)}
            className={`${
              isMobileView ? 'block' : 'hidden'
            } sm:w-1/3 justify-center items-center border text-black font-bold border-gray-300 px-4 py-2 rounded-lg hover:bg-gray-200 transition duration-300`}
          >
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </button>

          {isMobileView ? showFilters && FilterSection : FilterSection}
        </div>

        {showModal && (
          <Modal onClose={() => setShowModal(false)} title="Oops! Access Denied">
            <div className="bg-white p-4 py-0 rounded-md">
              <p className="text-gray-600 mb-4 khmer text-lg">ដើម្បីចូលទៅកាន់ឯកសារនេះ អ្នកត្រូវ subscribe.</p>
              <div className="flex justify-center">
                <button
                  onClick={goToSubscribe}
                  className="flex justify-center items-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Subscribe Now <FaLockOpen className="ml-2" />
                </button>
              </div>
            </div>
          </Modal>
        )}

        {showLoginModal && (
          <Modal onClose={() => setShowLoginModal(false)} title="Oops! Please login!">
            <div className="bg-white p-4 py-0 rounded-md">
              <p className="text-gray-600 mb-4">To access this document, you'll need to login.</p>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    navigate('/login');
                  }}
                  className="flex justify-center items-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Login <FaLockOpen className="ml-2" />
                </button>
              </div>
            </div>
          </Modal>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 overflow-auto pb-10 px-4">
          {filteredDocuments.map((doc, index) => (
            <div
              key={doc.DocumentID || index}
              className="transition duration-500 ease-in-out transform hover:scale-105"
              onClick={() => handleDocumentPress(doc.DocumentID, doc.Free)}
            >
              <div className={`border border-black rounded-lg p-4 flex flex-col justify-between leading-normal ${getBackgroundColor(doc.LevelName)}`}>
                <DocumentPreview doc={doc} accessStatus={accessStatus} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
}
