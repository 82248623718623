import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import Flashcard from '../../components/Vocabs/Flashcard';
import Loading from '../../components/Loading';

const VocabPage = () => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [flashcards, setFlashcards] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  
  const { setId } = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem('token');
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    try {

      setIsLoading(true)

    axios
      .get(`https://tonletalk-e5cea0c5c5df.herokuapp.com/vocabs/${setId}`, { headers })
      .then((response) => setFlashcards(response.data))
      .catch((error) => console.error('Error fetching data:', error));
    } finally {
      setIsLoading(false)
    }


  }, [setId, token]);

  const handleNextCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex === flashcards.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex === 0 ? flashcards.length - 1 : prevIndex - 1));
  };

  const handleNav = () => {
    navigate(`/vocabExercise/${setId}`);
  };

  if (isLoading) return <Loading />;


  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen bg-gray-100 pt-24 pb-24 px-4 lg:pt-32 lg:pb-24">
      {/* Render Flashcard component only if flashcards array has items */}
      {flashcards.length > 0 ? (
        <Flashcard
          flashcards={flashcards}
          currentCardIndex={currentCardIndex}
          handlePrevCard={handlePrevCard}
          handleNextCard={handleNextCard}
        />
      ) : (
        <p className="text-gray-500 text-lg">No flashcards available at the moment.</p>
      )}

      <div className="w-full my-5">
        <button
          onClick={handleNav}
          className="w-full border p-4 rounded-xl bg-blue-500 text-white hover:bg-blue-400 transition shadow"
        >
          Exercise
        </button>
      </div>

      <div className="mt-12 w-full">
        <h2 className="text-2xl font-semibold text-center mb-6">Words and Definitions</h2>
        <ul className="text-lg w-full bg-white border-2 rounded-lg overflow-hidden">
          {/* Render the word list only if flashcards exist */}
          {flashcards.length > 0 ? (
            flashcards.map((flashcard, index) => (
              <li key={index} className="w-full text-gray-800 py-4 px-6 bg-white border-b border-gray-200 text-xl md:text-2xl">
                <strong className="block">{flashcard.Term}:</strong>
                <span className="khmer text-gray-600">{flashcard.Definition}</span>
              </li>
            ))
          ) : (
            <p className="text-center text-gray-500">No words available.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default VocabPage;
