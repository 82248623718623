import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CiMap } from "react-icons/ci";
import Modal from '../components/Modal';
import PaymentForm from '../components/PaymentForm';
import { FaPencilAlt } from "react-icons/fa";
import Loading from '../components/Loading';

const AccountPage = () => {
    const [cardLast4, setCardLast4] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [interval, setInterval] = useState('');
    const [nextBillingDate, setNextBillingDate] = useState('');
    const [isCancelling, setIsCancelling] = useState(false);
    const [isChangingPlan, setIsChangingPlan] = useState(false);
    const [showCardChange, setShowCardChange] = useState(false);
    const [cancelDate, setCancelDate] = useState();
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [email, setEmail] = useState('');
    const [price, setPrice] = useState();
    
    
    const token = localStorage.getItem('token'); // Get the token from local storage

    const headers = {
        Authorization: `Bearer ${token}`
    };


    useEffect(() => {
        // Fetch the user's card details from your server
        // Example: GET /api/user/card
        // For now, we'll use a placeholder
        const fetchCardDetails = async () => {  
            setIsLoading(true);
            try {
                const response = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/subscription/get-subscription', {}, { headers });
                setCardLast4(response.data.cardLast4);

                if(response.data.interval === 'month' && response.data.interval_count === 1) {
                    setInterval('1 month');
                } else if(response.data.interval === 'month' && response.data.interval_count === 3) {
                    setInterval('3 months');
                } else if(response.data.interval === 'year' && response.data.interval_count === 1) {
                    setInterval('1 year');
                }

                setNextBillingDate(response.data.nextBillingDate);
                setCancelDate(response.data.cancellingDate);
                setEmail(response.data.email);
                setSubscriptionStatus(response.data.subscriptionStatus);
                setPrice(response.data.price);

            } catch (error) {
                console.error('Error fetching card details:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCardDetails();
    }, []);


    const handlePlanChange = async (newPriceId) => {
        setIsChangingPlan(true);
        setShowModal(false); // Close the modal
    
        try {
            const response = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/subscription/change-plan', { newPriceId }, { headers });
    
            if (response.status === 200) {
                toast.success(response.data.message);
                // Handle successful plan change
                window.location.reload();

            } else {
                toast.error(response.data.error || 'Failed to update the plan.');
            }
        } catch (error) {
            console.error('Error:', error.response || error);
            const errorMessage = error.response?.data?.error || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        } finally {
            setIsChangingPlan(false);
        }
    };
      

    const handleCancelConfirmation = () => {
        setIsCancelling(true);

        axios.post(
            `https://tonletalk-e5cea0c5c5df.herokuapp.com/subscription/cancel-subscription`,
            {},
            { headers }
        ).then((response) => {
            toast.success('Subscription cancelled successfully.');
            setShowCancelConfirmation(false); // Close the modal
            setIsCancelling(false);
            window.location.reload();
        }).catch(error => {
            toast.error('Error canceling subscription. Please try again.');
            setShowCancelConfirmation(false); // Close the modal
            setIsCancelling(false);
        });
    };

    const confirmCancellation = () => {
        setShowCancelConfirmation(true);
    };

    const plans = [{
        name: '1 month',
        price: '2.99 / month',
        duration: 30,
        priceId: 'price_1Oh42jImouIeU5G8ganPSG6h'
      }, {
        name: '3 months',
        price: '4.99 / 3 month',
        duration: 90,
        priceId: 'price_1Oh42uImouIeU5G8ySa3Tbsf'
      }, {
        name: '1 year',
        price: '14.99 / year',
        duration: 365,
        priceId: 'price_1Oh434ImouIeU5G8ZFevPiRd'
      }
    ]

    if(!isChangingPlan && !isCancelling && !isLoading) {

    return (
        <div className='h-screen flex justify-center items-center bg-gray-100 px-4'>
            <div className="w-96 p-6 bg-white  rounded-lg shadow-xl bg-gradient-to-r from-white to-[#F0FFFF]">
                <h1 className='text-2xl font-bold text-gray-800 mb-4'>Account Settings</h1>
                <p className="text-gray-700 mb-2">Email: <span className="font-semibold">{email}</span></p>

                <p className="text-gray-700 mb-2">Current Plan: <span className="font-semibold">{interval}, ${price}</span></p>
                <p className="text-gray-700 mb-2">{cancelDate ? 'Cancels on':'Next Billing'}: <span className="font-semibold">{cancelDate ? cancelDate:nextBillingDate}</span></p>
                <p className="text-gray-700 mb-6 flex flex-row items-center" >
                    Credit Card: **** **** **** {cardLast4}
                    <FaPencilAlt className='ml-2 cursor-pointer	' onClick={() => setShowCardChange(true)}/>
                </p>

                {showCardChange && (
                    <Modal onClose={() => setShowCardChange(false)} title="Change Card">
                        <PaymentForm/>
                    </Modal>
                )}

                <button 
                    className="mb-3 w-full flex items-center justify-start px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={confirmCancellation}
                    disabled={isCancelling}

                >
                    <svg className="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Cancel Subscription
                </button>
                {showCancelConfirmation && (
                    <Modal onClose={() => setShowCancelConfirmation(false)} title="Confirm Cancellation">
                        <div className="p-4">
                            <p className="text-lg text-gray-800 mb-6 khmer">អ្នកច្បាស់ថាលែងបង់?</p>
                            <div className="flex justify-evenly">
                                <button 
                                    onClick={handleCancelConfirmation}
                                    className="w-1/3 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
                                >
                                    Yes
                                </button>
                                <button 
                                    onClick={() => setShowCancelConfirmation(false)}
                                    className="w-1/3 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </Modal>

                )}
                <button 
                    className="mb-3 w-full flex items-center justify-start px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={() => setShowModal(true)}
                >
                    <svg className="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <CiMap />
                    </svg>
                    Change Plan
                </button>
                {subscriptionStatus === 'trialing' && (
                    <>
                        <p className="text-yellow-700 font-bold text-lg text-center">You are currently under trial!</p>
                        <p className="text-gray-500 text-sm text-center khmer">ខាងប្អូននឹងគិតលុយបើនៅ {nextBillingDate} បើអត់ cancel!</p>

                    </>
                )}
                {showModal && (
                <Modal onClose={() => setShowModal(false)} title="Change Plan">
                    <div className="space-y-4">
                    {/* Map over your plan options */}
                    {plans.map(plan => (
                        <button
                        key={plan.priceId}
                        onClick={() => handlePlanChange(plan.priceId)}
                        className="w-full text-left px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition duration-150 ease-in-out"
                        >
                        {plan.name} - {plan.price}
                        </button>
                    ))}
                    <div>
                        <p className='text-gray-500 text-center text-sm khmer'>**ដោយចុចលើគម្រោង អ្នកកំពុងធ្វើបច្ចុប្បន្នភាពដោយស្វ័យប្រវត្តិ!</p>
                    </div>
                    </div>
                </Modal>
                )}

            </div>
        </div>

    )} else {
        return (
            <Loading/>
        )
    }
};

export default AccountPage;
