import React, { useEffect, useState } from 'react';
import WordModal from './WordModal';
import { MdGTranslate } from "react-icons/md";

import { toast } from 'react-toastify';

const StoryComponent = ({ content, accessStatus }) => {

    const [showModal, setShowModal] = useState(false);
    const [definition, setDefinition] = useState(''); // Replace with the content of the story
    const [titleWord, setTitleWord] = useState(''); // Replace with the content of the story
    const [audioUrl, setAudioUrl] = useState(''); // Replace with the content of the story
    const [fetchingWord, setFetchingWord] = useState(false);
    const [translated, setTranslated] = useState('')
    const [isProcessing, setIsProcessing] = useState(false);

    function removeSpecialCharacter(word) {
      return word.replace(/[^\w\s]/gi, '');
    }

    const handleWordClick = (word) => {
        // Fetch the definition for the clicked word from the dictionary API
        // You can use your preferred method for API requests (fetch, axios, etc.)
        word = removeSpecialCharacter(word)
        setTitleWord(word)
        setFetchingWord(true)
        fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)
          .then(response => response.json())
          .then(data => {
            // Handle the API response, e.g., display the definition
            setDefinition(data[0]?.meanings[0]?.definitions[0]?.definition);
            setAudioUrl(data[0]?.phonetics[0]?.audio);
            setFetchingWord(false)
          })
          .then(() => {
            setShowModal(true) 
            
           })
          .catch(error => console.error('Error fetching data:', error));
      };

    // Split content by capturing words and spaces/newlines separately
    const formattedContent = content.split(/([^\s]+)/).map((segment, index) => {
      if (segment.match(/[^\s]+/)) {
          return (
              <span
                  key={index}
                  className='text-xl hover:text-blue-500 transition cursor-pointer'
                  onClick={() => handleWordClick(segment)}
              >
                  {segment}
              </span>
          );
      } else if (segment.match(/\n/)) {
          return <><br key={index} /><br /></>;
      } 
      else {
          return <span key={index}>{segment}</span>;
      }
  })

  const token = localStorage.getItem('token'); // Get the token from local storage
  const headers = {
    Authorization: `Bearer ${token}`
  };


  const handleTranslate = async (word) => {
    setIsProcessing(true); // Set isProcessing to true when translation starts
  
    try {
      if (accessStatus === 'granted') {
        const url = `https://tonletalk-e5cea0c5c5df.herokuapp.com/translate/?text=${word}`;
  
        const response = await fetch(url, { headers });
        const data = await response.json();
  
        // Assuming data.translatedText is the field in your JSON response
        setTranslated(data.translatedText);
        console.log(data.translatedText);
      } else {
        toast.warn('សុំបងបង់ក្លាយជា pro ដើម្បីប្រើប្រាស់', {
          className: 'khmer'
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsProcessing(false); // Set isProcessing to false when translation ends
    }
  };
  

  const handleClose = () => {
    setShowModal(false);
    setTranslated('')
  }

  return <div>{formattedContent}
        {
        showModal && (
          <WordModal onClose={handleClose} title={`Definition: ${titleWord}`} word={titleWord} audioUrl={audioUrl} definition={definition}>
            {definition ? <p>{definition}</p>: <p className='text-gray-500'>No definition found</p>}
            <div className='flex items-center mt-4'>

              {translated ? <p className='khmer font-bold text-2xl'>{translated}</p> : 
              <button
                className={`khmer flex hover:text-gray-500 transition ${isProcessing && 'text-gray-500 cursor-not-allowed'}`}
                onClick={() => handleTranslate(titleWord)}
                disabled={isProcessing} // Disable button when isProcessing is true
              >
                <p className='mr-1'>{!isProcessing ? 'បកជាខ្មែរ': 'កំពុងបក'}</p>
                <MdGTranslate size={24} className='inline-block' />
              </button>
              }
            </div>
          </WordModal>
        )
      }
        {fetchingWord && 
            <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black p-4 rounded-lg shadow-lg'>
              <p className='text-white'>Loading...</p>
            </div>

        }

      </div>;
};

export default StoryComponent;
