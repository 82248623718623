import React from 'react';
import { Link } from 'react-router-dom';

const LearningHero = () => {
    return (
        <div className="hero bg-gradient-to-r  lg:h-96 md:h-96 h-64 sm:h-64 p-4 flex items-center justify-center from-purple-500 to-blue-500 transition-colors duration-300 cursor-pointer" >
            {/* <Link to="/video" className="text-center"> */}
                {/* Background overlay to darken the image on hover for better contrast */}
                <div className=" p-4 rounded-lg hover:bg-opacity-40 transition-all duration-300">
                    <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-extrabold text-white text-center mb-6 drop-shadow-md animate-pulse khmer">រៀនអង់គ្លេសពី​ 0 ជាមួយ <span className='ibm text-cyan-400'>Tonle Talk</span></h1>
                    <p className="text-center text-lg sm:text-xl md:text-2xl lg:text-2xl text-white mb-4 animate-bounce khmer">ចាប់ផ្តើមមើលមេរៀន</p>
                    <div className="mt-4 text-center">
                        <a href='/video' className="bg-white text-purple-800 hover:text-purple-600 hover:bg-opacity-90 text-xl font-bold py-2 px-6 rounded-full transition-all duration-300 shadow-lg">
                            Watch Videos
                        </a>
                    </div>
                </div>
            {/* </Link> */}
        </div>
    );
};

export default LearningHero;
