import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaUser, FaSignOutAlt } from 'react-icons/fa';
import axios from 'axios';

export default function Header() {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [accessStatus, setAccessStatus] = useState(false);
  const navigate = useNavigate();

  const isAuthenticated = () => Boolean(localStorage.getItem('token'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/user/verify', {
        headers: { Authorization: `Bearer ${token}` }
      }).then((response) => {
        setAccessStatus(response.data.user.accessStatus);
      }).catch(console.error);
    }
  }, []);

  const navItems = [
    { to: "/video", label: "វីឌេអូ" },
    { to: "/allLearning", label: "រៀន" },
    { to: "/allVocab", label: "ពាក្យ" },
    { to: "/allDocuments", label: "អាន" },
    { to: "/allWriting", label: "សរសេរ" },
  ];

  const authItems = isAuthenticated()
    ? [
        { to: "/account", label: accessStatus === 'revoked' ? 'បង់ប្រាក់' : 'គណនី' },
        { to: "/learning", label: "ការរៀនខ្ញុំ" },
        { label: "ចាកចេញ", onClick: handleLogout }
      ]
    : [
        { to: "/pricing", label: "តម្លៃ" },
        { to: "/login", label: "ចូល" },
        { to: "/register", label: "ចុះឈ្មោះ" }
      ];

  return (
    <header className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white shadow-lg fixed w-full z-20">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between py-4">
          <div className="flex items-center">
            <button onClick={() => navigate(-1)} className="mr-4 lg:hidden text-2xl">
              <FaArrowLeft />
            </button>
            <Link to="/" className="text-2xl md:text-4xl sm:text-2xl font-bold tracking-tight hover:text-blue-100 transition duration-300">
              Tonle Talk
            </Link>
          </div>

          <nav className="hidden lg:flex space-x-4">
            {navItems.map((item, index) => (
              <Link key={index} to={item.to} className="hover:text-blue-100 transition duration-300 khmer text-lg md:text-xl sm:text-base ">
                {item.label}
              </Link>
            ))}
          </nav>

          <div className="hidden lg:flex items-center space-x-4">
            {authItems.map((item, index) => (
              item.to ? (
                <Link key={index} to={item.to} className="hover:text-blue-100 transition duration-300 khmer flex items-center text-lg md:text-xl sm:text-base ">
                  {item.icon && <span className="mr-2">{item.icon}</span>}
                  {item.label}
                </Link>
              ) : (
                <button key={index} onClick={item.onClick} className="hover:text-blue-100 transition duration-300 khmer flex items-center text-lg md:text-xl sm:text-base ">
                  {item.icon && <span className="mr-2">{item.icon}</span>}
                  {item.label}
                </button>
              )
            ))}
          </div>

          <button onClick={() => setIsNavVisible(!isNavVisible)} className="lg:hidden text-2xl focus:outline-none">
            {isNavVisible ? '✖' : '☰'}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isNavVisible && (
        <div className="lg:hidden bg-gradient-to-r from-cyan-600 to-blue-600 py-2 transition-all duration-300 ease-in-out">
          <nav className="flex flex-col space-y-2 px-4">
            {[...navItems, ...authItems].map((item, index) => (
              item.to ? (
                <Link 
                  key={index} 
                  to={item.to} 
                  className="hover:bg-blue-400 hover:bg-opacity-50 rounded-md py-2 px-3 transition duration-300 khmer flex items-center text-xl md:text-lg sm:text-base" 
                  onClick={() => setIsNavVisible(false)}
                >
                  {item.label}
                </Link>
              ) : (
                <button 
                  key={index} 
                  onClick={() => { item.onClick(); setIsNavVisible(false); }} 
                  className="hover:bg-blue-400 hover:bg-opacity-50 rounded-md py-2 px-3 transition duration-300 khmer flex items-center w-full text-left text-xl md:text-lg sm:text-base"
                >
                  {item.label}
                </button>
              )
            ))}
          </nav>
        </div>
      )}
    </header>
  );
}