import React from 'react';
import { motion } from 'framer-motion';
import { FaBook, FaHeadphones, FaPencilAlt } from 'react-icons/fa';
import { TbCardsFilled } from 'react-icons/tb';

const LearningJourneySection = () => {
  const learningAreas = [
    { icon: FaBook, title: "Reading", color: "blue" },
    { icon: FaHeadphones, title: "Listening", color: "green" },
    { icon: FaPencilAlt, title: "Writing", color: "yellow" },
    { icon: TbCardsFilled, title: "Flashcard", color: "purple" },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  return (
    <div className="lg:w-1/2 mt-10 lg:mt-0">
      <div className="relative">
        <motion.div 
          className="absolute top-0 left-0 w-64 h-64 bg-blue-200 rounded-full mix-blend-multiply filter blur-xl opacity-70"
          animate={{
            scale: [1, 1.2, 1],
            rotate: [0, 90, 0],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
        <motion.div 
          className="absolute bottom-0 right-0 w-64 h-64 bg-green-200 rounded-full mix-blend-multiply filter blur-xl opacity-70"
          animate={{
            scale: [1, 1.2, 1],
            rotate: [0, -90, 0],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        />
        <motion.div 
          className="relative bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-3xl p-8 shadow-2xl"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <h2 className="text-3xl font-bold text-blue-800 mb-6">Your Learning Journey</h2>
          <div className="grid grid-cols-2 gap-6">
            {learningAreas.map((area, index) => (
              <motion.div 
                key={index}
                className="flex flex-col items-center p-4 bg-white bg-opacity-50 rounded-xl shadow-lg"
                variants={itemVariants}
                whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
              >
                <motion.div 
                  className={`text-4xl text-${area.color}-600 mb-2`}
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.5 }}
                >
                  <area.icon />
                </motion.div>
                <span className={`text-${area.color}-700 font-semibold`}>{area.title}</span>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LearningJourneySection;