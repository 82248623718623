import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';
import Loading from '../components/Loading';
import FavoritedWords from '../components/History/FavoriteWords';
import LearningHistory from '../components/History/LearningHistory';
import { useNavigate } from 'react-router-dom';
import VideoHistory from '../components/History/VideoHistory';
import VocabProgress from '../components/History/VocabProgress';

const LearningPage = ({ accessStatus }) => {
  const [documents, setDocuments] = useState([]);
  const [filterValue, setFilterValue] = useState('notCompleted');
  const [isFetching, setIsFetching] = useState(false);
  const [vocabs, setVocabs] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [selectedSection, setSelectedSection] = useState('all');

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        const response = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling/userProgressHistory', {}, { headers });
        setDocuments(response.data);
        setIsFetching(false);
      } catch (error) {
        console.error('Error fetching documents:', error);
        setIsFetching(false);
      }
    };

    fetchData();
  }, [token]);

  const getVocabs = async () => {
    try {
      const response = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling/getFavWords', {}, { headers });
      setVocabs(response.data);
    } catch (error) {
      console.error('Error fetching vocabs:', error);
    }
  };

  const deleteWordFromFavorites = async (word) => {
    try {
      const response = await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling/deleteWord', { word }, { headers });
      if (response.status === 200) {
        getVocabs();
        setDeleted(true);
        setTimeout(() => setDeleted(false), 1500);
      }
    } catch (error) {
      console.error('Error deleting word:', error);
    }
  };

  useEffect(() => {
    getVocabs();
  }, [token]);

  if (isFetching) return <Loading />;

  const renderSection = () => {
    switch (selectedSection) {
      case 'videos':
        return <VideoHistory />;
      case 'vocab':
        return (
          <VocabProgress
            handleVocabPress={(vocabId) => navigate(`/vocab/${vocabId}`)}
          />
        );
      case 'favorites':
        return (
          <FavoritedWords 
            vocabs={vocabs} 
            deleteWordFromFavorites={deleteWordFromFavorites} 
            deleted={deleted} 
          />
        );
      case 'learning':
        return (
          <LearningHistory
            documents={documents}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            handleDocumentPress={(documentId) => navigate(`/document/${documentId}`)}
            getBackgroundColor={(levelName) => {
              switch (levelName) {
                case 'Beginner':
                  return 'bg-gradient-to-r from-sky-100 to-blue-200';
                case 'Intermediate':
                  return 'bg-gradient-to-r from-orange-100 to-cyan-100';
                case 'Advanced':
                  return 'bg-gradient-to-r from-purple-100 to-rose-100';
                default:
                  return 'bg-gray-100';
              }
            }}
          />
        );
      default:
        return (
          <>
            <VideoHistory />
            <VocabProgress
              handleVocabPress={(vocabId) => navigate(`/vocab/${vocabId}`)}
            />
            <FavoritedWords 
              vocabs={vocabs} 
              deleteWordFromFavorites={deleteWordFromFavorites} 
              deleted={deleted} 
            />
            <LearningHistory
              documents={documents}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              handleDocumentPress={(documentId) => navigate(`/document/${documentId}`)}
              getBackgroundColor={(levelName) => {
                switch (levelName) {
                  case 'Beginner':
                    return 'bg-gradient-to-r from-sky-100 to-blue-200';
                  case 'Intermediate':
                    return 'bg-gradient-to-r from-orange-100 to-cyan-100';
                  case 'Advanced':
                    return 'bg-gradient-to-r from-purple-100 to-rose-100';
                  default:
                    return 'bg-gray-100';
                }
              }}
            />
          </>
        );
    }
  };

  return (
    <div className="bg-gray-200 flex justify-center items-center p-6 pt-12 pb-12 md:pt-8 md:pb-8 lg:pb-0 lg:pt-0 md:px-12">
      <div className="container mx-auto py-10 px-10 rounded-lg border border-black my-10 sm:my-16 lg:my-32 bg-gradient-to-r from-white to-[#F0FFFF]">
        <motion.h1 
          className="text-3xl font-semibold text-center text-blue-700 mb-8 khmer"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          ការរៀនខ្ញុំ
        </motion.h1>
        
        <motion.div 
          className="mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <label htmlFor="section-select" className="block text-lg md:text-xl font-medium text-gray-700 mb-2 khmer">
            ជ្រើសរើសផ្នែក
          </label>
          <div className="relative">
            <select
              id="section-select"
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
              className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 khmer"
            >
              <option value="all">All</option>
              <option value="videos">Video Course History</option>
              <option value="vocab">Vocab Progress</option>
              <option value="favorites">Favorited Words</option>
              <option value="learning">Document Learning History</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <FaChevronDown />
            </div>
          </div>
        </motion.div>
        
        {renderSection()}
      </div>
    </div>
  );
};

export default LearningPage;