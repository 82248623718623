import React, { useState, useEffect } from 'react';
import { FaApple, FaMobileAlt, FaTabletAlt, FaLaptop, FaTimes } from 'react-icons/fa';
import { SiAndroid } from 'react-icons/si';
import { motion, AnimatePresence } from 'framer-motion';

export default function DownloadAppSection() {
  const [isIOSModalOpen, setIsIOSModalOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleAndroidInstall = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-gray-50 to-white overflow-hidden">
      <motion.div 
        className="max-w-7xl mx-auto"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.h2 
          className="text-4xl font-bold mb-8 text-center text-gray-900 khmer"
          variants={itemVariants}
        >
          ទាញយកកម្មវិធី Tonle Talk
        </motion.h2>
        <motion.p 
          className="text-xl mb-12 text-center text-gray-600 khmer"
          variants={itemVariants}
        >
          រៀនគ្រប់ពេលវេលា គ្រប់ទីកន្លែង តាមរយៈកម្មវិធីទូរស័ព្ទរបស់យើង
        </motion.p>
        
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <motion.div 
            className="bg-white rounded-2xl shadow-xl overflow-hidden relative"
            variants={itemVariants}
            whileHover={{ scale: 1.05, boxShadow: "0 20px 30px rgba(0,0,0,0.1)" }}
            transition={{ duration: 0.3 }}
          >
            <div className="p-8">
              <FaApple className="text-6xl text-gray-800 mb-6 mx-auto" />
              <h3 className="text-2xl font-semibold text-center mb-4">iOS</h3>
              <p className="text-gray-600 text-center mb-6 khmer">ទាញយកសម្រាប់ iPhone និង iPad</p>
              <motion.button 
                onClick={() => setIsIOSModalOpen(true)}
                className="block w-full bg-black text-white text-center py-4 rounded-xl hover:bg-gray-800 transition duration-300 text-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Install on iOS
              </motion.button>
            </div>
            <motion.div 
              className="absolute -bottom-4 -left-4 w-24 h-24 bg-blue-100 rounded-full"
              animate={{
                scale: [1, 1.2, 1],
                rotate: [0, 90, 0],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          </motion.div>

          <motion.div 
            className="bg-white rounded-2xl shadow-xl overflow-hidden relative"
            variants={itemVariants}
            whileHover={{ scale: 1.05, boxShadow: "0 20px 30px rgba(0,0,0,0.1)" }}
            transition={{ duration: 0.3 }}
          >
            <div className="p-8">
              <SiAndroid className="text-6xl text-green-500 mb-6 mx-auto" />
              <h3 className="text-2xl font-semibold text-center mb-4">Android</h3>
              <p className="text-gray-600 text-center mb-6 khmer">ទាញយកសម្រាប់ទូរស័ព្ទ Android</p>
              <motion.button 
                onClick={handleAndroidInstall}
                className="block w-full bg-green-500 text-white text-center py-4 rounded-xl hover:bg-green-600 transition duration-300 text-lg font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Install on Android
              </motion.button>
            </div>
            <motion.div 
              className="absolute -top-4 -right-4 w-24 h-24 bg-green-100 rounded-full"
              animate={{
                scale: [1, 1.2, 1],
                rotate: [0, -90, 0],
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            />
          </motion.div>
        </div>

        <motion.div 
          className="mt-16 text-center"
          variants={itemVariants}
        >
          <p className="khmer text-xl text-gray-700 mb-8">ប្រើបានលើគ្រប់ឧបករណ៍ - ទូរស័ព្ទដៃ ថេប្លេត និងកុំព្យូទ័រ</p>
          <div className="flex justify-center space-x-8">
            <motion.div
              whileHover={{ scale: 1.1, rotate: 5 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <FaMobileAlt className="text-4xl text-blue-500" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1, rotate: -5 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <FaTabletAlt className="text-4xl text-green-500" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1, rotate: 5 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <FaLaptop className="text-4xl text-purple-500" />
            </motion.div>
          </div>
        </motion.div>
      </motion.div>

      {/* iOS Installation Modal */}
      <AnimatePresence>
        {isIOSModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
            onClick={() => setIsIOSModalOpen(false)}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-lg p-6 max-w-3xl w-full"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold text-gray-900">Install on iOS</h3>
                <button 
                  onClick={() => setIsIOSModalOpen(false)} 
                  className="text-gray-500 hover:text-gray-700"
                  aria-label="Close modal"
                >
                  <FaTimes size={24} />
                </button>
              </div>
              <div className="mb-6 flex items-center justify-center">
                <video 
                  controls 
                  className=" h-96 max-w-md rounded-lg shadow-lg"
                >
                  <source src="https://tonletalk.s3.us-east-005.backblazeb2.com/tonleTalkPWADemo.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="space-y-4 text-gray-700 text-center">
                <p className="font-semibold">Follow these steps to install Tonle Talk on your iOS device:</p>
                <ol className="list-decimal list-inside space-y-2">
                  <li>Tap the Share button at the bottom of the screen</li>
                  <li>Scroll down and tap "Add to Home Screen"</li>
                  <li>Tap "Add" in the top right corner</li>
                </ol>
                <p className="text-gray-600">
                  You'll now see the Tonle Talk icon on your home screen, just like a regular app!
                </p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}