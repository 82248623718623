import React from 'react';
import { FaEnvelope, FaUser, FaCalendarAlt, FaVenusMars } from 'react-icons/fa';

const ProfileInfo = ({ profile }) => {
  return (
    <div className="bg-white rounded-lg p-4 mt-6 border border-gray-200">
      <div className="flex items-center justify-start mb-4 border-b border-gray-200 pb-2">
        <FaUser className="text-green-500 text-xl" />
        <span className="ml-4 text-lg md:text-xl font-semibold text-gray-800">{profile?.Name || 'Anonymous'}</span>
      </div>
      <div className="flex items-center justify-start mb-4 border-b border-gray-200 pb-2">
        <FaEnvelope className="text-blue-500 text-xl" />
        <span className="ml-4 text-lg md:text-xl font-semibold text-gray-800">{profile?.Email || 'Not Provided'}</span>
      </div>
      <div className="flex items-center justify-start mb-4 border-b border-gray-200 pb-2">
        <FaCalendarAlt className="text-yellow-500 text-xl" />
        <span className="ml-4 text-lg md:text-xl font-semibold text-gray-800">{profile?.Age || 'Not Provided'} years old</span>
      </div>
      <div className="flex items-center justify-start">
        <FaVenusMars className="text-purple-500 text-xl" />
        <span className="ml-4 text-lg md:text-xl font-semibold text-gray-800">{profile?.Gender || 'Not Provided'}</span>
      </div>
    </div>
  );
};

export default ProfileInfo;
