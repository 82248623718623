import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMicrophone, FaPen, FaBook, FaListUl, FaFile } from 'react-icons/fa';
import { TbCardsFilled } from 'react-icons/tb';
import { motion } from 'framer-motion';

const PracticePage = () => {
    const navigate = useNavigate();

    const handleLearning = () => navigate('/allLearning');
    const handleWritingPractice = () => navigate('/allWriting');
    const handleReadingPractice = () => navigate('/allDocuments');
    const handleVocabPractice = () => navigate('/allvocab');

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 100
            }
        }
    };

    const buttonVariants = {
        hover: {
            scale: 1.05,
            transition: {
                duration: 0.2
            }
        },
        tap: {
            scale: 0.95
        }
    };

    return (
        <motion.div 
            className="min-h-screen bg-gray-100 flex justify-center items-center py-12"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
        >
            <div className="container mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 py-12 bg-white border-gray-200 border-2 rounded-lg">
                <motion.div 
                    className="khmer grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8"
                    variants={containerVariants}
                >
                    {/* Reading Practice */}
                    <motion.div 
                        className="bg-gradient-to-r from-purple-100 to-rose-200 p-6 rounded-lg text-center hover:shadow-lg transition duration-300 flex flex-col justify-between min-h-[300px]"
                        variants={itemVariants}
                        whileHover={{ scale: 1.05 }}
                    >
                        <div>
                            <motion.div
                                animate={{
                                    y: [0, -5, 5, 0],
                                }}
                                transition={{
                                    duration: 2,
                                    repeat: Infinity,
                                }}
                            >
                                <FaBook className="text-purple-500 text-4xl mx-auto mb-4" />
                            </motion.div>
                            <h2 className="text-xl md:text-2xl font-semibold mb-2">ហ្វឹកហាត់អាន</h2>
                            <p className="text-lg md:text-xl text-gray-600 mb-4">អភិវឌ្ឍការអានរបស់អ្នកជាមួយនឹងការអានអត្ថបទ និងឆ្លើយសំណួរស្រដៀងនឹង iELTs ឬ Toefl</p>
                        </div>
                        <motion.button
                            onClick={handleReadingPractice}
                            className="text-lg bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-600 transition mt-auto"
                            variants={buttonVariants}
                            whileHover="hover"
                            whileTap="tap"
                        >
                            ចាប់ផ្តើមអាន
                        </motion.button>
                    </motion.div>

                    {/* Vocabulary Practice */}
                    <motion.div 
                        className="bg-gradient-to-r from-green-100 to-lime-100 p-6 rounded-lg text-center hover:shadow-lg transition duration-300 flex flex-col justify-between min-h-[300px]"
                        variants={itemVariants}
                        whileHover={{ scale: 1.05 }}
                    >
                        <div>
                            <motion.div
                                animate={{
                                    scale: [1, 1.2, 1],
                                    rotate: [0, 10, -10, 0],
                                }}
                                transition={{
                                    duration: 2,
                                    repeat: Infinity,
                                    repeatType: "reverse"
                                }}
                            >
                                <TbCardsFilled className="text-green-500 text-4xl mx-auto mb-4" />
                            </motion.div>
                            <h2 className="text-xl md:text-2xl font-semibold mb-2">ហ្វឹកហាត់ពាក្យ</h2>
                            <p className="text-lg md:text-xl text-gray-600 mb-4">បង្កើនវាក្យសព្ទរបស់អ្នកជាមួយនឹងលំហាត់ពាក្យដូចជា Flash Cards</p>
                        </div>
                        <motion.button
                            onClick={handleVocabPractice}
                            className="text-lg bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition mt-auto"
                            variants={buttonVariants}
                            whileHover="hover"
                            whileTap="tap"
                        >
                            ចាប់ផ្តើមរៀនពាក្យ
                        </motion.button>
                    </motion.div>

                    {/* Writing Practice */}
                    <motion.div 
                        className="bg-gradient-to-r from-orange-100 to-cyan-100 p-6 rounded-lg text-center hover:shadow-lg transition duration-300 flex flex-col justify-between min-h-[300px]"
                        variants={itemVariants}
                        whileHover={{ scale: 1.05 }}
                    >
                        <div>
                            <motion.div
                                animate={{
                                    x: [0, 5, -5, 0],
                                }}
                                transition={{
                                    duration: 2,
                                    repeat: Infinity,
                                }}
                            >
                                <FaPen className="text-yellow-500 text-4xl mx-auto mb-4" />
                            </motion.div>
                            <h2 className="text-xl md:text-2xl font-semibold mb-2">ហ្វឹកហាត់ការសរសេរ</h2>
                            <p className="text-lg md:text-xl text-gray-600 mb-4">ពង្រឹងជំនាញសរសេររបស់អ្នកដោយអនុវត្តលំហាត់សរសេរ និងមានកំណែភ្លាមៗជាមួយ AI</p>
                        </div>
                        <motion.button
                            onClick={handleWritingPractice}
                            className="text-lg bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600 transition mt-auto"
                            variants={buttonVariants}
                            whileHover="hover"
                            whileTap="tap"
                        >
                            ចាប់ផ្តើមសរសេរ
                        </motion.button>
                    </motion.div>
                    
                    {/* Learning with Documents */}
                    <motion.div 
                        className="bg-gradient-to-r from-sky-100 to-blue-200 p-6 rounded-lg text-center hover:shadow-lg transition duration-300 flex flex-col justify-between min-h-[300px]"
                        variants={itemVariants}
                        whileHover={{ scale: 1.05 }}
                    >
                        <div>
                            <motion.div
                                animate={{
                                    y: [0, -5, 5, 0],
                                }}
                                transition={{
                                    duration: 2,
                                    repeat: Infinity,
                                }}
                            >
                                <FaFile className="text-blue-500 text-4xl mx-auto mb-4" />
                            </motion.div>
                            <h2 className="text-xl md:text-2xl font-semibold mb-2">រៀនជាមួយឯកសារ</h2>
                            <p className="text-lg md:text-xl text-gray-600 mb-4">រៀនជាមួយឯកសារសម្រាប់គ្រប់កំរិត បូកនឹង grammar</p>
                        </div>
                        <motion.button
                            onClick={handleLearning}
                            className="text-lg bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition mt-auto"
                            variants={buttonVariants}
                            whileHover="hover"
                            whileTap="tap"
                        >
                            ចាប់ផ្តើមរៀនឯកសារ
                        </motion.button>
                    </motion.div>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default PracticePage;