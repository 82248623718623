import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoggedOutPage from './LoggedOutPage';
import LoggedInPage from './LoggedInPage';

export default function HomePage({ accessStatus }) {
  const [visited, setVisited] = useState(localStorage.getItem('visited'));
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const navigateTo = (path) => {
    navigate(path);
  }

  const cta = () => {
    if (!token) {
      return 'ចុះឈ្មោះ FREE!'
    } else if (accessStatus === 'granted') {
      return 'ចាប់ផ្តើមរៀន'
    } else {
      return 'Try for free'
    }
  }

  const handleCta = () => {
    if (!token) {
      navigate('/pricing');
    } else if (accessStatus === 'granted') {
      navigate('/allDocuments');
    } else {
      navigate('/account');
    }
  }

  return (
    token ? 
      <LoggedInPage /> : 
      <LoggedOutPage 
        navigateTo={navigateTo}
        cta={cta}
        handleCta={handleCta}
        accessStatus={accessStatus}
      />
  )
}
