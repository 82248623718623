import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";
import { FcSpeaker } from "react-icons/fc";
import axios from 'axios';
import { CiSaveDown2 } from "react-icons/ci";

const WordModal = ({ title, children, onClose, audioUrl, definition, word }) => {
  const [audio] = useState(new Audio(audioUrl));
  const [isFavorite, setIsFavorite] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const playAudio = () => {
    audio.play();
  };

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const handleFavorite = async () => {
    setIsFavorite(true);
    try {
      // Call the backend API to favorite the word
      await axios.post('https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling/word', { word: word, definition }, { headers });
      setIsFavorite(true);
    } catch (error) {
      console.error('Error favoriting word:', error);
    } finally {
      setIsFavorite(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-100 bg-opacity-10 overflow-y-auto h-full w-full z-20 flex justify-center items-center">
      <div className="bg-white rounded shadow-lg p-5 md:p-8 mx-8 md:mx-0 w-full md:w-3/4 lg:w-2/3 xl:w-1/3">
        <div className="flex justify-between items-center">
          <div className='flex flex-row items-center justify-center'>
            <h2 className="text-2xl font-bold whitespace-nowrap">{title}</h2>
            {audioUrl && (
              <button onClick={playAudio} className="text-lg p-2">
                <FcSpeaker size={30}/>
              </button>
            )}
          </div>


          <div className='flex items-center justify-center'>
            {!isFavorite ? (
              <CiSaveDown2 size={24} onClick={handleFavorite} className={`cursor-pointer mr-2 transition hover:text-blue-500 text-gray-500`} />
            ): 
            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500 mr-2"></div>
          }
            <RxCross2 size={24} onClick={onClose} className='cursor-pointer hover:text-blue-500 transition'/>
          </div>
        </div>
        <div className="mt-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default WordModal;
