import React from 'react';

const WritingBanner = () => {
    return (
        <div className="hero bg-gradient-to-r from-blue-800 to-purple-700 lg:h-96 md:h-96 h-64 sm:h-64 p-4 flex items-center justify-center">
            <div className="text-center">
                <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-extrabold text-white mb-6 drop-shadow-md animate-pulse khmer">រៀនសរសេរជាមួយ <span className="text-cyan-400 ibm">Tonle Talk</span></h1>
                <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl text-white mb-4 animate-bounce khmer">ប្រើប្រាស់នូវការកែរជាមួយ AI Grading</p>
                {/* Add any additional content or components here */}
            </div>
        </div>
    );
};

export default WritingBanner;
