import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaBook, FaHeadphones, FaPencilAlt, FaChalkboardTeacher } from 'react-icons/fa';
import LearningJourneySection from './LearningJourneySection';

export default function LoggedInHeroSection({ name }) {
  const iconVariants = {
    hover: {
      scale: 1.2,
      rotate: 360,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    }
  };

  return (
    <section className="relative bg-gradient-to-br px-4 md:px-10 from-blue-50 to-blue-100 overflow-hidden">
      <div className="container mx-auto px-4 py-16 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 z-10">
          <motion.h1 
            className="text-4xl lg:text-5xl font-bold text-blue-800 mb-4 khmer"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            ទទួលបានភាពស្ទាត់ជំនាញជាភាសាអង់គ្លេសជាមួយ Tonle Talk
          </motion.h1>
          <motion.p 
            className="text-lg text-blue-600 mb-8 khmer"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            សូមស្វាគមន៍ការត្រឡប់មកវិញ, <span className="text-blue-800 font-bold">{name}</span>! បន្តដំណើររបស់អ្នកទៅកាន់ជំនាញភាសាអង់គ្លេស និងបង្កើនផលិតភាពរបស់អ្នក.
          </motion.p>
          <Link to="/practice">
            <motion.button 
              className="bg-blue-600 text-white py-3 px-6 rounded-full hover:bg-blue-700 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Continue Your Practice
            </motion.button>
          </Link>
        </div>
        <LearningJourneySection />
        
      </div>
      <div className="absolute bottom-0 left-0 right-0">
        <svg viewBox="0 0 1440 120" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0L60 10C120 20 240 40 360 46.7C480 53 600 47 720 43.3C840 40 960 40 1080 46.7C1200 53 1320 67 1380 73.3L1440 80V120H1380C1320 120 1200 120 1080 120C960 120 840 120 720 120C600 120 480 120 360 120C240 120 120 120 60 120H0V0Z" fill="#F3F4F6"/>
        </svg>
      </div>
    </section>
  );
}