import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaUserPlus, FaSignInAlt } from 'react-icons/fa';

const LoggedOutScreen = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex flex-col items-center justify-center p-6">
      {/* Image Section */}
      <motion.div 
        className="mb-8"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <img
          src="https://tonletalk.s3.us-east-005.backblazeb2.com/tonleTalkLandingPage.png"
          alt="Tonle Talk"
          className="w-40 h-40 md:w-56 md:h-56 object-cover "
        />
      </motion.div>

      {/* Main Content */}
      <motion.div 
        className="bg-white w-full max-w-md shadow-xl rounded-2xl p-8 text-center"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {/* Title with animated pulse */}
        <div className="mb-6">
          <motion.h2 
            className="text-3xl font-bold mb-4 text-blue-600 khmer"
            animate={{ scale: [1, 1.02, 1] }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            #1 Website នៅខ្មែរសម្រាប់រៀនអង់គ្លេស
          </motion.h2>
          <p className="text-blue-500 text-lg khmer">អ្នករៀន 4000+ នាក់</p>
          <motion.p 
            className="text-blue-600 text-xl font-bold mt-2"
            animate={{ opacity: [1, 0.7, 1] }}
            transition={{ duration: 1.5, repeat: Infinity }}
          >
            Sign up for free
          </motion.p>
        </div>

        {/* Sign-up Button */}
        <div className="space-y-4">
          <Link to="/register">
            <motion.div 
              className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-3 px-6 rounded-full w-full text-center text-lg font-semibold shadow-md"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <FaUserPlus className="inline-block mr-2" />
              Sign Up for Free
            </motion.div>
          </Link>
          <p className="text-gray-600 text-sm">
            By signing up, you accept Tonle Talk's{' '}
            <Link to="/privacy-policy" className="text-blue-500 underline hover:text-blue-700 transition duration-300">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </motion.div>

      {/* Login Section */}
      <motion.div 
        className="mt-6 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <p className="text-gray-800">
          Already have an account?{' '}
          <Link to="/login" className="text-blue-600 font-semibold hover:underline transition duration-300">
            <FaSignInAlt className="inline-block mr-1" />
            Log in
          </Link>
        </p>
      </motion.div>
    </div>
  );
};

export default LoggedOutScreen;