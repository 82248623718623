import React from 'react';
import { motion } from 'framer-motion';
import { HiVideoCamera, HiChatAlt2, HiAcademicCap, HiSparkles } from 'react-icons/hi';

export default function NoPremiumDisplay() {
  const premiumFeatures = [
    { icon: HiVideoCamera, title: "100+ Premium Video Courses", description: "ទទួលបានសិទ្ធិចូលមើលមេរៀន 110+ គុណភាពសម្រាប់រៀនអង់គ្លេស" },
    { icon: HiChatAlt2, title: "Exclusive Community Access", description: "ចូលរួមជាមួយសហគមន៍ Telegram សម្រាប់សួរសំណួរជាមួយគ្រូ" },
    { icon: HiAcademicCap, title: "AI-Powered Learning Assistant", description: "ធ្វើអន្តរកម្មជាមួយ AI chatbot កម្រិតខ្ពស់របស់យើងសម្រាប់ជំនួយផ្ទាល់ខ្លួន" },
  ];

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-100 py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.div 
          className="text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600 sm:text-6xl khmer">
            មុខងារពិសេស
          </h2>
          <p className="mt-4 text-xl text-gray-600 khmer">
            បង្កើនបទពិសោធន៍សិក្សារបស់អ្នកជាមួយនឹងសមាជិកភាពពិសេសរបស់យើង
          </p>
        </motion.div>

        <motion.div 
          className="mt-16 grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, staggerChildren: 0.1 }}
        >
          {premiumFeatures.map((feature, index) => (
            <motion.div 
              key={index}
              className="bg-white khmer overflow-hidden shadow-xl rounded-3xl border border-gray-100"
              whileHover={{ y: -10, boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' }}
              transition={{ duration: 0.3 }}
            >
              <div className="px-8 py-10">
                <div className="flex items-center">
                  <div className="flex-shrink-0 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-2xl p-4">
                    <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                  </div>
                  <div className="ml-6 w-0 flex-1">
                    <h3 className="text-xl font-bold text-gray-900 truncate">
                      {feature.title}
                    </h3>
                    <p className="mt-3 text-base text-gray-500">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <motion.div 
          className="mt-20 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <motion.a
            href="/account"
            className="inline-flex items-center px-10 py-5 border border-transparent text-xl font-bold rounded-full text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700 focus:outline-none focus:ring-4 focus:ring-offset-4 focus:ring-blue-500 transition-all duration-300 shadow-lg hover:shadow-2xl relative overflow-hidden group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <span className="relative z-10 khmer">ចាប់ផ្តើមសមាជិកភាពពិសេស</span>
            <motion.span
              className="absolute inset-0 bg-gradient-to-r from-indigo-600 to-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              initial={{ x: '100%' }}
              whileHover={{ x: 0 }}
              transition={{ type: 'tween' }}
            />
            <HiSparkles className="ml-3 h-6 w-6 text-yellow-300" />
          </motion.a>
          <motion.p
            className="mt-4 text-lg font-semibold text-indigo-600 khmer"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            7 ថ្ងៃដំបូង FREE!
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
}