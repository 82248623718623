import React from 'react';
import '../styles/Document.css'; // Replace with the path to your stylesheet
import { FaLock } from "react-icons/fa6";

import { TbProgressCheck, TbCheck } from "react-icons/tb";

export default function DocumentPreview({ doc, accessStatus }) {
  return (
    <div className=" rounded overflow-hidden khmer">
      <button 
        className="w-full h-full  text-left text-gray-800"
      >
        <div className="mb-2">
          <h3 className="text-xl font-bold lg:whitespace-nowrap md:whitespace-nowrap sm:whitespace-nowrap flex items-center">{doc.Title}
          {doc.Completed === 0 || doc.Completed === 1 ? (
              <>
                {doc.Completed ? <TbCheck className='ml-2' size={20} color='green'/> : <TbProgressCheck className='ml-2' size={20} color='gray'/>}
              </>
          ) : null}
          </h3>
          <p className="text-md text-gray-600  whitespace-nowrap">មុខវិជ្ជា: <span className='text-green-800 font-bold'>{doc.CategoryName}</span></p>
        </div>
        <div className="flex justify-between text-md text-gray-600 ">
          <p>កំរិត: <span className='text-green-800 font-bold'>{doc.LevelName}</span></p>

          {accessStatus === 'granted' ? null :
            <p className='text-gray-900'>{!doc.Free ? <FaLock /> : 'FREE'}</p>
          }
        </div>
      </button>
    </div>

  );
}
