import React from 'react';
import { motion } from 'framer-motion';
import { FaBookOpen, FaChalkboardTeacher, FaPenAlt, FaLayerGroup } from 'react-icons/fa';

export default function LoggedInSection({ navigateTo }) {
  const features = [
    {
      icon: <FaBookOpen className="text-5xl text-white" />,
      title: "ចេះអាន",
      description: "ចូលអានឯកសារជាច្រើន ដើម្បីបង្កើនការអានរបស់អ្នក",
      path: "/allDocuments",
      color: "from-blue-500 to-blue-600",
    },
    {
      icon: <FaChalkboardTeacher className="text-5xl text-white" />,
      title: "រៀនពី 0",
      description: "ចូលរួមជាមួយការសិក្សារអង់គ្លេសពីកំរិតដំបូង",
      path: "/allLearning",
      color: "from-green-500 to-green-600",
    },
    {
      icon: <FaPenAlt className="text-5xl text-white" />,
      title: "ចេះសរសេរ",
      description: "រៀនសរសេរអង់គ្លេសជាមួយ AI Grading រហ័សទាន់ចិត្ត",
      path: "/allWriting",
      color: "from-purple-500 to-purple-600",
    },
    {
      icon: <FaLayerGroup className="text-5xl text-white" />,
      title: "Flashcard",
      description: "បង្កើនចំណាំរបស់អ្នកជាមួយនឹងប័ណ្ណពាក្យ",
      path: "/flashcards",
      color: "from-yellow-500 to-yellow-600",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <section className="container mx-auto px-4 py-12 bg-gray-50">
      <motion.h2 
        className="text-4xl font-bold text-gray-800 mb-8 text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Continue Your Journey
      </motion.h2>
      <motion.div 
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {features.map((feature, index) => (
          <motion.div
            key={index}
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`bg-gradient-to-br ${feature.color} rounded-lg shadow-lg overflow-hidden cursor-pointer`}
            onClick={() => navigateTo(feature.path)}
          >
            <div className="p-6 flex flex-col items-center">
              <motion.div
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
              >
                {feature.icon}
              </motion.div>
              <h3 className="mt-4 text-2xl font-semibold text-center text-white khmer">
                {feature.title}
              </h3>
              <p className="mt-2 text-center text-white khmer font-semibold">
                {feature.description}
              </p>
              <motion.button 
                className="mt-4 bg-white text-gray-800 py-2 px-6 rounded-full text-sm font-bold hover:bg-gray-100 transition duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Start Now
              </motion.button>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
}