import React from 'react';

const formatContent = (content) => {
    return content.split(/([^\s]+)/).map((segment, index) => {
        if (segment.match(/[^\s]+/)) {
            return (
                <span
                    key={index}
                    className='text-md transition cursor-pointer'
                >
                    {segment}
                </span>
            );
        } else if (segment.match(/\n/)) {
            return <React.Fragment key={index}><br /><br /></React.Fragment>;
        } else {
            return <span key={index}>{segment}</span>;
        }
    });
}

export default formatContent;
