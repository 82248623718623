import React from 'react';
import { FaLock } from 'react-icons/fa';

const PromptPreview = ({ prompt, accessStatus }) => {
  const token = localStorage.getItem('token');

  return (
    <div className="border border-gray-300 p-4 rounded-lg hover:shadow-lg transition duration-300 bg-gradient-to-r from-gray-200 to-gray-100">
      <div className='flex mb-2'>
        <h3 className="text-lg font-semibold text-blue-500">{prompt.title}</h3>
        {prompt.Score && 
          <div className='ml-2 flex items-center'>
            <p className=''>{prompt.Score}/30</p>
            {prompt.Score >= 25 && <span className="text-green-500 ml-2 font-bold">A</span>}
            {prompt.Score >= 20 && prompt.Score < 25 && <span className="text-blue-500 ml-2">B</span>}
            {prompt.Score >= 15 && prompt.Score < 20 && <span className="text-yellow-500 ml-2">C</span>}
            {prompt.Score < 15 && <span className="text-red-500 ml-2">D</span>}
          </div>
        }
      </div>
      <div className="flex items-center justify-between text-sm text-gray-700">
        <div className='flex'>
          <p className='px-2 py-1 bg-gray-300 rounded-full'>{prompt.CategoryName}</p>
          {(accessStatus === 'revoked' || !token) &&
            <div className='flex items-center ml-2'>
                <FaLock className='' color='black'/>
            </div>
          }
        </div>
        <div>
          {(accessStatus === 'revoked' || !token) && 
            <span className="px-2 py-1 bg-gray-300 rounded-full  mr-2">{prompt.free ? 'FREE' : 'PRO'}</span>
          }

          <span className="px-2 py-1 bg-gray-300 rounded-full">{prompt.LevelName}</span>
        </div>
      </div>
    </div>
  );
};

export default PromptPreview;
