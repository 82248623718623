import React from 'react';
import { Link } from 'react-router-dom';

const PricingPage = () => {
  const email = localStorage.getItem('email');

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white rounded-lg shadow-md p-6 max-w-sm mx-auto bg-gradient-to-r from-white to-[#F0FFFF]">
        <h1 className="text-2xl font-bold text-gray-800">Premium</h1>
        <h1 className='animate-pulse text-blue-700'>{email}</h1>
        <ul className="m-4 space-y-2 text-gray-600 font-bold list-disc khmer">
          <li>រៀនបានគ្រប់</li>
          <li>ប្រើបានគ្មានកំនត់</li>
          <li>រៀនពេលណា នៅទីណាក៏បាន</li>
        </ul>
        <p className="text-gray-600 khmer">សុំជ្រើសរើសកញ្ចប់ដែលសាកសមនឹងអ្នក៖ </p>
        <ul className="mt-4 space-y-4">
          <li className="bg-gray-100 rounded-lg p-4 hover:bg-blue-500 hover:text-white transition duration-300">
            <Link to="/subscribe/1-month" className="block">
              <div className="font-semibold">1 Month</div>
              <div className="text-lg">$2.99</div>
              <div className="font-semibold text-sm khmer text-green-700 hover:text-white">FREE 7 ថ្ងៃ</div>

            </Link>
          </li>
          <li className="bg-gray-100 rounded-lg p-4 hover:bg-blue-500 hover:text-white transition duration-300">
            <Link to="/subscribe/3-months" className="block">
              <div className="font-semibold">3 Months</div>
              <div className="text-lg flex items-center">$4.99 <span className='bg-green-500 text-white p-1 px-2 text-sm rounded-lg ml-1  font-bold'>44.44% OFF</span></div>
              <div className="font-semibold text-sm khmer text-green-700 hover:text-white">FREE 7 ថ្ងៃ</div>

            </Link>
          </li>
          <li className="bg-gray-100 rounded-lg p-4 hover:bg-blue-500 hover:text-white transition duration-300">
            <Link to="/subscribe/1-year" className="block">
              <div className="font-semibold">1 Year</div>
                <div className="text-lg items-center flex">$14.99 <span className='bg-green-500 text-white p-1 px-2 text-sm rounded-lg ml-1 font-bold'>80.77% OFF</span></div> 
              <div className="font-semibold text-sm khmer text-green-700 hover:text-white">FREE 7 ថ្ងៃ</div>

            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PricingPage;
