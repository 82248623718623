import React from 'react';

const ReadingHero = () => {
    return (
        <div className="hero bg-gradient-to-r from-cyan-400 to-yellow-200 lg:h-96 md:h-96 h-64 sm:h-64 p-4 flex items-center justify-center  " >
            <div className="text-center">
                <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-extrabold text-white mb-6 drop-shadow-md animate-pulse khmer">រៀនអានអង់គ្លេសជាមួយ <span className='ibm text-cyan-400'>Tonle Talk</span></h1>
                <p className="text-lg sm:text-xl md:text-2xl lg:text-2xl text-white mb-4 animate-bounce">Learn English with Technology.</p>
                {/* Add any additional content or components here */}
            </div>
        </div>
    );
};

export default ReadingHero;
