import axios from "axios";

const token = localStorage.getItem('token');

const headers = {
    Authorization: `Bearer ${token}`
};

const verifyUser = async () => {
    try {
        const response = await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/user/verify', { headers });
        return response.data.user.accessStatus;
    } catch (error) {
        return false;
    }
}

export default verifyUser;
