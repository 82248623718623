import React from 'react';

const FilterButtons = ({ selectedLevel, handleLevelFilter }) => {
  const levels = ['All', 'In Progress', 'New', 'Beginner', 'Intermediate', 'Advanced'];

  return (
    <div className="flex flex-wrap justify-center space-x-2 space-y-2 mb-4">
      {levels.map((level, index) => (
        <button
          key={index}
          onClick={() => handleLevelFilter(level)}
          className={`px-4 py-2 text-sm md:text-md rounded-full shadow-md transition-colors duration-300 transform ${
            selectedLevel === level 
              ? 'bg-blue-600 text-white hover:bg-blue-700 hover:scale-105' 
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300 hover:scale-105'
          }`}
        >
          {level}
        </button>
      ))}
    </div>
  );
};

export default FilterButtons;
