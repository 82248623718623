import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import '../../styles/FlashCard.css';

const Flashcard = ({ flashcards, currentCardIndex, handlePrevCard, handleNextCard }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="flip-container relative w-full">
      <div className={`flashcard h-80  ${isFlipped ? 'flipped' : ''}`}>
        {/* Front Side (Term) */}
        <div className="front paper-effect absolute w-full h-full backface-hidden flex flex-col justify-between">
          <div className="p-8 flex items-center justify-center">
            <p className="text-2xl md:text-3xl font-bold text-center text-blue-700">{flashcards[currentCardIndex].Term}</p>
          </div>
          <div className="flex justify-between mt-6 px-8">
            <button onClick={handlePrevCard} className="text-blue-500 hover:text-blue-600 focus:outline-none">
              <FaArrowLeft className="w-8 h-8" />
            </button>
            <button onClick={handleNextCard} className="text-blue-500 hover:text-blue-600 focus:outline-none">
              <FaArrowRight className="w-8 h-8" />
            </button>
          </div>
          <div className="flex justify-center items-center flex-col w-full mt-4 px-8">
            <button onClick={toggleFlip} className="khmer text-white border border-gray-400 bg-blue-500 py-2 px-6 rounded-full mb-2 hover:bg-blue-600 transition duration-300">
              បង្ហាញនិយមន័យ
            </button>
            <h1 className="khmer text text-lg text-center">{currentCardIndex + 1}/{flashcards.length}</h1>
          </div>
        </div>

        {/* Back Side (Definition) */}
        <div className="back paper-effect absolute w-full h-full backface-hidden flex flex-col justify-between bg-gray-100">
          <div className="p-8 flex items-center justify-center">
            <p className="text-gray-700 text-center khmer text-2xl md:text-3xl">{flashcards[currentCardIndex].Definition}</p>
          </div>
          <div className="flex justify-between mt-6 px-8">
            <button onClick={handlePrevCard} className="text-blue-500 hover:text-blue-600 focus:outline-none">
              <FaArrowLeft className="w-8 h-8" />
            </button>
            <button onClick={handleNextCard} className="text-blue-500 hover:text-blue-600 focus:outline-none">
              <FaArrowRight className="w-8 h-8" />
            </button>
          </div>
          <div className="flex justify-center items-center flex-col w-full mt-4 px-8">
            <button onClick={toggleFlip} className="khmer text-white border border-gray-400 bg-blue-500 py-2 px-6 rounded-full mb-2 hover:bg-blue-600 transition duration-300">
              មើលពាក្យ
            </button>
            <h1 className="khmer text text-lg text-center">{currentCardIndex + 1}/{flashcards.length}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flashcard;
