import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import verifyUser from '../../functions/Verify';
import AccessDenied from '../../components/AccessDenied';
import '../../styles/Document.css'; // Replace with the path to your stylesheet
import Answer from '../../components/Answer';
import shuffleArray from '../../functions/shuffleArray';
import StoryComponent from '../../components/StoryComponent';
import Loading from '../../components/Loading';

const DocumentPage = () => {
  const { documentId } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState({ correct: 0, incorrect: 0 });
  const [accessStatus, setAccessStatus] = useState(false);
  const token = localStorage.getItem('token'); // Get the token from local storage
  const [shuffledQuestions, setShuffledQuestions] = useState([]); // Get the token from local storage

  const takeToSubmit = () => {
    const submitButton = document.getElementById('submit-button');

    // Scroll to the submit button with smooth behavior
    if (submitButton) {
        submitButton.scrollIntoView({ behavior: 'smooth' });
    }
  }
      
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const navigate = useNavigate()

  const handleBack = () => {
    // Go back to the previous page
    navigate(-1);
  };

  const handleRetry = () => {
    // Reset the state or perform any necessary actions to retry
    setSelectedAnswers({});
    setSubmitted(false);
    setResult({ correct: 0, incorrect: 0 });
  };

  useEffect(() => {
    const getStatus = async () => {
      const access = await verifyUser();
      setAccessStatus(access);
    }

    getStatus()
  }, [])
  
  const fetchDoc = async () => {
    axios.get(`https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling/${documentId}`, { headers })
    .then(response => {
      setDocumentData(response.data);
    }).then(()=> {

    })
    .catch(error => console.error('Error fetching document:', error));
  }

  useEffect( () => {
    fetchDoc()
  }, [documentId]);

  useEffect(() => {
    if (documentData) {
    // Shuffle questions
    const shuffledQuestions = Object.values(documentData.questions);
    shuffleArray(shuffledQuestions);
  
    // Shuffle answers within each question
    shuffledQuestions.forEach(question => {
      shuffleArray(question.Answers);
    });

    setShuffledQuestions(shuffledQuestions);
  }
  }, [documentData]);

  const handleAnswerSelect = (questionId, answerId) => {
    if (!submitted) {
      setSelectedAnswers(prev => ({ ...prev, [questionId]: answerId }));
    }
  };
  

  const handleSubmit = (questionLength) => {
    try {
    if (!submitted) {
      let correctCount = 0;
      let incorrectCount = 0;


      Object.values(documentData.questions).forEach(question => {
        const userAnswer = selectedAnswers[question.QuestionID];
        const correctAnswer = question.Answers.find(answer => answer.IsCorrect).AnswerID;
  
        if (userAnswer === correctAnswer) {
          correctCount++;
        } else if (userAnswer) {
          incorrectCount++;
        }
      });

      if (incorrectCount === 0 && correctCount === 0) {
        incorrectCount = questionLength;
      }
  
      setResult({ correct: correctCount, incorrect: incorrectCount });
      setSubmitted(true);

  
      axios.post(
        `https://tonletalk-e5cea0c5c5df.herokuapp.com/documentHandling/submit-answers`,
        {
            documentId: documentData.DocumentID,
            userAnswers: Object.entries(selectedAnswers).map(([questionId, answerId]) => ({
              questionId: parseInt(questionId),
              answerId: parseInt(answerId),
            })),
        },
        { headers } // Include the headers in the request
    )
        .catch(error => {
          console.error('Error submitting answers:', error);
          // Handle the error (e.g., show an error message to the user)
        });
    }
  } catch (error) {
    console.log(error);
  } finally {
    fetchDoc()
  }
  };
  
  

  if (documentData === null || shuffledQuestions.length === 0) {
    return <Loading/>;
  }






  if(documentData.Free || accessStatus === 'granted') {

  return (
    <div className="flex flex-col p-2.5 mb-5 lg:pt-24 md:pt-24 pt-20 sm:pt-20 ">
      <div className="border p-2.5 mb-5 rounded bg-white shadow-md question-card">
        <h1 className="text-2xl font-bold mb-5">{documentData.Title} 
        {documentData.Completed === 0 || documentData.Completed === 1 ? (
          <span className={`${documentData.Completed ? 'text-green-700' : 'text-gray-500'}`}>
            {documentData.Completed ? '- Completed' : `- Incomplete - Score: ${documentData.Score}/${Object.keys(documentData.questions).length}`}
          </span>
        ) : null}

        </h1>
        
        <p className="text-lg ">
          <StoryComponent content={documentData.Content} accessStatus={accessStatus}/>
        </p>
      </div>
      {shuffledQuestions.map((question, index) => {
        const userAnswerId = selectedAnswers[question.QuestionID];
        const correctAnswer = question.Answers.find(ans => ans.IsCorrect);
        const userIsCorrect = userAnswerId === correctAnswer?.AnswerID;
        return(
            <div key={question.QuestionID} className="mb-3.5 p-2.5 px-4 ">
                <div className='flex flex-row '>
                  <p className="text-xl font-bold ">
                    Q{index + 1}: {question.QuestionText} 
                  </p>

                  {submitted && (userIsCorrect ? <p className='text-lg ml-5 text-green-500'> ✓</p> : <p className='text-lg ml-5 text-red-500'> ✗</p>)}
              </div>
              <div className='px-4 mt-2'>
            {question.Answers.map(answer => (
              <Answer
                key={answer.AnswerID}
                text={answer.AnswerText}
                onSelect={() => handleAnswerSelect(question.QuestionID, answer.AnswerID)}
                selected={userAnswerId === answer.AnswerID}
                isCorrect={answer.IsCorrect}
                showResult={submitted}
                userAnswerCorrect={userIsCorrect}
              />
            ))}
            </div>
            {submitted && (
              <p className="text-lg mt-1 ml-2.5 text-green-800 khmer">
                Explanation: {question.Explanation}
              </p>
            )}
          </div>
        )
                
        
    })}
      {!submitted && (
        <button id='submit-button' onClick={() => handleSubmit(Object.keys(documentData.questions).length)} className="hover:from-blue-700 hover:to-blue-800 transition text-white font-bold py-2 px-4 rounded  bg-gradient-to-r from-blue-600 to-blue-800">
          Submit
        </button>
      )}
      {submitted && (
        <div className="mt-4">
          <p className="text-green-500">Correct Answers: {result.correct}</p>
          <p className="text-red-500">Incorrect Answers: {result.incorrect}</p>
        </div>
      )}

      <div className="flex justify-between mt-4">
      {submitted && (
        <>
        <button onClick={handleBack} className="hover:from-orange-500 hover:to-yellow-600 transition text-white font-bold py-2 px-4 rounded rounded-r-none  bg-gradient-to-r from-orange-400 to-yellow-600 w-full">
          Back
        </button>

          <button onClick={handleRetry} className="hover:from-blue-700 hover:to-blue-800 transition text-white font-bold py-2 px-4 rounded  rounded-l-none bg-gradient-to-r from-blue-600 to-blue-800 w-full">
            Retry
          </button>
          </>
        )}
        <button className='fixed bottom-2 right-2' onClick={takeToSubmit}>
            <p className="text-sm text-gray-700 bg-green-300 bg-opacity-80 px-2 py-1 rounded-md shadow-md">
                Click "Submit" to save your learning history
            </p>
        </button>

        </div>
    </div>
  )} 
  else 
  {
    return (
      <AccessDenied/>
    )
  }
};

export default DocumentPage;
