import React from 'react';
import FlashcardSetItem from './FlashcardSetItem';

const FlashcardSetList = ({ sets, handleSelectSet }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {sets.map((set, index) => (
        <FlashcardSetItem key={index} set={set} handleSelectSet={handleSelectSet} />
      ))}
    </div>
  );
};

export default FlashcardSetList;
