import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FaBook, FaTrophy, FaChartLine } from 'react-icons/fa';
import Loading from '../Loading';

const VocabProgress = ({ handleVocabPress }) => {
  const [vocabProgress, setVocabProgress] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVocabProgress = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/history/vocab', { headers });
        setVocabProgress(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching vocab progress:', error);
        setIsLoading(false);
      }
    };

    fetchVocabProgress();
  }, []);

  if (isLoading) return <Loading />;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <motion.div 
      className="mt-12 mx-auto"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="flex items-center mb-8">
        <FaBook className="lg:text-xl text-lg text-blue-600 mr-4" />
        <h1 className="lg:text-xl text-lg font-bold text-gray-800">Vocab Progress</h1>
      </div>
      {vocabProgress.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {vocabProgress.map((vocabSet, index) => (
            <motion.button
              key={index}
              className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-start"
              onClick={() => handleVocabPress(vocabSet.SetID)}
              variants={itemVariants}
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.98 }}
            >
              <h2 className="lg:text-lg text-base font-semibold text-gray-800 mb-3">{vocabSet.VocabSetName}</h2>
              <div className="flex items-center mb-4">
                <FaTrophy className="text-yellow-500 mr-2" />
                <p className="text-gray-600">
                  Score: <span className="font-bold text-blue-600">{vocabSet.Score}%</span>
                </p>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
                <motion.div
                  className="bg-blue-600 h-2 rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${vocabSet.Score}%` }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                />
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <FaChartLine className="mr-2" />
                <span>Keep improving!</span>
              </div>
            </motion.button>
          ))}
        </div>
      ) : (
        <motion.div 
          className="text-center py-8 bg-white rounded-lg shadow-md"
          variants={itemVariants}
        >
          <FaBook className="text-5xl text-gray-300 mb-4 mx-auto" />
          <p className="lg:text-lg text-base text-gray-500 mb-6">No vocab progress available yet.</p>
          <button 
            className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300"
            onClick={() => handleVocabPress('new')}
          >
            Start Learning Vocab
          </button>
        </motion.div>
      )}
    </motion.div>
  );
};

export default VocabProgress;