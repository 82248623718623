import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditProfileModal({ isVisible, onClose }) {
  const token = localStorage.getItem('token');
  const [name, setName] = useState('');
  const [profilePicURL, setProfilePicURL] = useState('');
  const [availablePictures, setAvailablePictures] = useState([]);
  const [isSaving, setIsSaving] = useState(false); // For loading state while saving
  const [loading, setLoading] = useState(false); // For initial loading state

  const headers = { Authorization: `Bearer ${token}` };

  const fetchProfile = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data
      const response = await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/profile', { headers });
      setName(response.data.Name);
      setProfilePicURL(response.data.ProfilePicURL);
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchProfile();

      const fetchAvailablePictures = async () => {
        try {
          const response = await axios.get('https://tonletalk-e5cea0c5c5df.herokuapp.com/profile/pictures', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setAvailablePictures(response.data);
        } catch (error) {
          console.error('Error fetching profile pictures:', error);
        }
      };
      fetchAvailablePictures();
    }
  }, [token, isVisible]);

  const handleSave = async () => {
    setIsSaving(true); // Start loading for saving
    try {
      const response = await axios.put('https://tonletalk-e5cea0c5c5df.herokuapp.com/profile', 
        { name, profilePicURL },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      if (response.status === 200) {
        toast.success('Profile updated successfully!');
        onClose(); // Close the modal after successful update
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error('Failed to update profile. Please try again later.');
    } finally {
      setIsSaving(false); // Stop saving loading
      setTimeout(() => {
        window.location.reload(); // Reload after 1 second
      }, 1000);
    }
  };
  
  const handleOutsideClick = (e) => {
    if (e.target.id === 'modalBackdrop') {
      onClose();
    }
  };

  if (!isVisible) return null; // Don't render the modal if it's not visible

  return (
    <div
      id="modalBackdrop"
      onClick={handleOutsideClick}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg relative">
        <button
          onClick={onClose}
          className="absolute top-4 text-2xl right-4 text-gray-500 hover:text-gray-700 transition"
        >
          &times;
        </button>

        <h1 className="text-2xl font-bold text-gray-800 mb-4">Edit Profile</h1>

        {/* Loading state */}
        {loading ? (
          <div className="flex flex-col items-center justify-center h-full space-y-4">
            <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin"></div>
            <h2 className="text-xl font-semibold text-blue-600">Loading...</h2>
            <p className="text-gray-500">Please wait while we load your profile</p>
          </div>

        ) : (
          <>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              className="border border-gray-300 rounded-md w-full px-4 py-2 mb-4 focus:ring-blue-500 focus:border-blue-500"
            />

            <h2 className="text-xl font-semibold text-gray-700 mb-4">Choose a Profile Picture</h2>

            <div className="grid grid-cols-5 gap-4 mb-6">
              {availablePictures.map((picture, index) => (
                <button
                  key={index}
                  onClick={() => setProfilePicURL(picture.url)}
                  className="relative group"
                >
                  <img
                    src={picture.url}
                    alt="profile option"
                    className={`w-15 h-15 md:w-20 md:h-20 rounded-full border-2 ${
                      picture.url === profilePicURL
                        ? 'border-blue-500'
                        : 'border-gray-300'
                    } group-hover:border-blue-500 transition-all`}
                  />
                  {picture.url === profilePicURL && (
                    <span className="absolute inset-0 flex items-center justify-center text-white font-bold bg-black bg-opacity-40 rounded-full">
                      Selected
                    </span>
                  )}
                </button>
              ))}
            </div>

            <button
              onClick={handleSave}
              disabled={isSaving} // Disable the button while saving
              className={`bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 w-full ${
                isSaving && 'opacity-50 cursor-not-allowed'
              }`}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </>
        )}
      </div>
    </div>
  );
}
